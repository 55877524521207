import { Center, HStack, Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbCircleDashed } from "react-icons/tb";

export const FileBlankSlate: FC = () => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Center
			bg={colors.whiteAdjusted}
			py={4}
			borderWidth={1}
			borderStyle="dashed"
			rounded="md"
		>
			<HStack spacing={2}>
				<TbCircleDashed />
				<Text>Keine Dateien hochgeladen</Text>
			</HStack>
		</Center>
	);
};
