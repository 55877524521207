import { db } from "@/core";
import {
	type BaustelleYearlyStatement,
	type Employee,
	mapStatementAnswerToReadable,
} from "@msuite/katana";
import { HStack, Text, useDocument } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface ResponseProps {
	response: BaustelleYearlyStatement;
}

export const Response: FC<ResponseProps> = ({ response }) => {
	/** Hooks */
	const { data: employee } = useDocument<Employee>(db, {
		path: `mitarbeiter/${response.created_by}`,
	});

	/** Render */
	return (
		<HStack>
			<Text fontSize="sm">
				{employee?.fullname} hat mit {"'"}
				{mapStatementAnswerToReadable(response.answer)}
				{"'"} geantwortet.
			</Text>
		</HStack>
	);
};
