import type { Moment } from "@msuite/katana";
import { Box } from "@msuite/picasso";
import type { FC } from "react";
import { BaseItem } from "./base-item";

/** Props Interface */
interface WeekItemProps {
	week: Moment;
	tabIndex: number;
}

export const WeekItem: FC<WeekItemProps> = ({ week, tabIndex }) => {
	/** Constants */
	const calendarWeekString = week.format("WW");
	const isOutOfScope = false;

	/** Render */
	return (
		<Box
			borderRightWidth={1}
			pr={2}
		>
			<BaseItem
				moment={week}
				mode="week"
				isInactive={isOutOfScope}
				alignLeft
				tabIndex={tabIndex}
			>
				{calendarWeekString}
			</BaseItem>
		</Box>
	);
};
