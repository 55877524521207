import { ax, db } from "@/core";
import { SelectEmployeesModal } from "@/modals/employees/select-employees-modal";
import {
	type Absence,
	type EducationMainEvent,
	type Employee,
	absenceCategoryArrayByType,
	absenceCategoryToReadable,
	absenceStatusToReadable,
	absenceTypeArray,
	absenceTypeToReadable,
	moment,
} from "@msuite/katana";
import {
	BlankSlate,
	Center,
	DocumentString,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Portal,
	Select,
	Text,
	Textarea,
	VStack,
	useAbsenceStatusOptions,
	useApi,
	useDisclosure,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormError } from "./schema";

/** Props Interface */
interface AbsenceFormProps {
	hasEmployeeSelection: boolean;
	selectedEmployeeIds?: string[];
	setSelectedEmployeeIds?: (employeeIds: string[]) => void;
}

export const AbsenceForm: FC<AbsenceFormProps> = ({
	hasEmployeeSelection,
	selectedEmployeeIds,
	setSelectedEmployeeIds,
}) => {
	/** Context */
	const methods = useFormContext<Partial<Absence>>();
	const [type, startDate, endDate, employeeId, status] = useWatch({
		control: methods.control,
		name: ["type", "von", "bis", "mitarbeiterId", "status"],
	});
	const categories = absenceCategoryArrayByType(type);
	const { options: statusOptions } = useAbsenceStatusOptions();

	/** Hooks */
	const selectEmployeesModal = useDisclosure();
	const { data } = useApi<{
		education: EducationMainEvent | null;
		collision: boolean;
	}>(ax, {
		url: `/v2/employees/${employeeId}/absences/education-collision?startDate=${startDate}&endDate=${endDate}`,
		dependencies: [
			startDate ?? "",
			endDate ?? "",
			employeeId ?? "",
			"absences",
			"education-collision",
		],
	});

	/** Render */
	return (
		<VStack>
			{hasEmployeeSelection && (
				<Fragment>
					{selectEmployeesModal.isOpen && (
						<SelectEmployeesModal
							isOpen={selectEmployeesModal.isOpen}
							onClose={selectEmployeesModal.onClose}
							defaultSelectedEmployeeIds={selectedEmployeeIds ?? []}
							onChange={(employeeIds) => {
								setSelectedEmployeeIds?.(employeeIds);
							}}
						/>
					)}
					<Popover
						trigger="hover"
						placement="bottom-start"
					>
						<PopoverTrigger>
							<div>
								<FormControl>
									<FormLabel>Mitarbeiter</FormLabel>
									<Input
										readOnly
										value={`${selectedEmployeeIds?.length ?? 0} Mitarbeiter ausgewählt`}
										cursor="pointer"
										onClick={selectEmployeesModal.onOpen}
									/>
								</FormControl>
							</div>
						</PopoverTrigger>
						<Portal>
							<PopoverContent
								maxHeight="20rem"
								width="30rem"
							>
								<VStack
									padding={2}
									spacing={1}
									overflowY="scroll"
								>
									{!selectedEmployeeIds?.length && (
										<Center>
											<BlankSlate
												minH="0"
												heading="Keine Mitarbeiter ausgewählt"
												subtext="Klicken Sie auf das Feld, um Mitarbeiter auszuwählen."
											/>
										</Center>
									)}
									{selectedEmployeeIds?.map((employeeId) => (
										<Text key={employeeId}>
											<DocumentString<Employee>
												db={db}
												path={`mitarbeiter/${employeeId}`}
												fields={["vorname", "nachname"]}
											/>
										</Text>
									))}
								</VStack>
							</PopoverContent>
						</Portal>
					</Popover>
				</Fragment>
			)}
			<Grid
				templateColumns="1fr 1fr"
				gap={6}
			>
				<FormControl>
					<FormLabel>Von</FormLabel>
					<Input
						autoFocus
						type="date"
						{...methods.register("von")}
					/>
					<FormError name="von" />
				</FormControl>
				<FormControl>
					<FormLabel>Bis</FormLabel>
					<Input
						type="date"
						{...methods.register("bis")}
					/>
					<FormError name="bis" />
				</FormControl>
			</Grid>
			{data?.collision && (
				<FormControl>
					<FormLabel>Kollision mit Ereignis</FormLabel>
					<VStack
						spacing={0}
						fontSize="sm"
					>
						<Text>
							{data.education
								? `Schulung: ${data.education.title} (${moment().getSpanString(
										moment(data.education.startDate),
										moment(data.education.endDate),
									)})`
								: "Es gibt eine Kollision."}
						</Text>
						<Text>
							Bitte überprüfen Sie die Daten und passen Sie diese gegebenenfalls
							an.
						</Text>
					</VStack>
				</FormControl>
			)}
			<FormControl>
				<FormLabel>Typ</FormLabel>
				<Select {...methods.register("type")}>
					{absenceTypeArray.map((type) => (
						<option
							key={type}
							value={type}
						>
							{absenceTypeToReadable(type)}
						</option>
					))}
				</Select>
				<FormError name="type" />
			</FormControl>
			<FormControl>
				<FormLabel>Kategorie</FormLabel>
				<Select
					{...methods.register("category")}
					placeholder={
						categories.length > 0 ? "Kategorie auswählen" : "Nicht benötigt"
					}
					isDisabled={categories.length === 0}
				>
					{categories.map((category) => (
						<option
							key={category}
							value={category}
						>
							{absenceCategoryToReadable(category)}
						</option>
					))}
				</Select>
				<FormError name="category" />
			</FormControl>
			<FormControl>
				<FormLabel>Kommentar</FormLabel>
				<Textarea
					placeholder="Beliebigen Kommentar hinzufügen..."
					{...methods.register("comment")}
				/>
			</FormControl>
			{!status ||
				(statusOptions.includes(status) && (
					<FormControl>
						<FormLabel>Status</FormLabel>
						<Select
							placeholder="Status auswählen"
							{...methods.register("status")}
						>
							{statusOptions.map((status) => (
								<option
									key={status}
									value={status}
								>
									{absenceStatusToReadable(status)}
								</option>
							))}
						</Select>
						<FormError name="status" />
					</FormControl>
				))}
		</VStack>
	);
};
