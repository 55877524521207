import type { EmployeeWithAdditionalData } from "@msuite/katana";
import { TableSeparator } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface TableSeparatorWrapperProps {
	original?: EmployeeWithAdditionalData | undefined | null;
}

export const TableSeparatorWrapper: FC<TableSeparatorWrapperProps> = ({
	original,
}) => {
	/** Render */
	return (
		<TableSeparator
			label={
				original?.additionalData.employeeGroup.bezeichnung ?? "Ohne Bezeichnung"
			}
		/>
	);
};
