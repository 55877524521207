import { numbi } from "@msuite/katana";
import { Box, Grid } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { TimelineIconLine } from "./timeline-icon-line";

export const Timeline: FC = () => {
	/** Render */
	return (
		<Fragment>
			<Grid
				templateColumns="100px 1fr"
				style={{
					contentVisibility: "auto",
				}}
			>
				<TimelineIconLine />
				<Box height={`${numbi().random(30, 200)}px`}>
					<p>HELLO</p>
				</Box>
			</Grid>
		</Fragment>
	);
};
