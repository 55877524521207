import {
	Box,
	Center,
	Grid,
	HStack,
	Portal,
	useKeyPressEvent,
} from "@msuite/picasso";
import { type FC, useEffect, useId, useRef, useState } from "react";
import { useLightboxContext } from "./context";

/** Props Interface */
interface FileLightBoxProps {
	children: React.ReactNode;
}

export const LIGHTBOX_ID = "LIGHTBOX-IDENTIFIER";

export const FileLightBox: FC<FileLightBoxProps> = ({ children }) => {
	/** Context */
	const { lightboxId } = useLightboxContext();

	/** State */
	const [src, setSrc] = useState<string | null>(null);
	const [isLarge, setIsLarge] = useState<boolean>(false);
	const [otherSources, setOtherSources] = useState<string[]>([]);
	const [index, setIndex] = useState<number>(0);

	/** Hooks */
	const baseImageRef = useRef<HTMLDivElement>(null);
	const renderId = useId();
	useKeyPressEvent("Escape", onClose);
	useKeyPressEvent("ArrowRight", handleGoToNext);
	useKeyPressEvent("ArrowLeft", handleGoToPrev);

	/** Functions */
	function findChildrenSrc() {
		if (!baseImageRef.current) return null;
		const childrenElement = baseImageRef.current.querySelector("img");
		if (!childrenElement) return null;
		const _src = childrenElement.getAttribute("src");
		if (!_src) return null;
		return _src;
	}

	function onOpenLargeImage() {
		setIsLarge(true);
		setSrc(findChildrenSrc());
	}

	function onClose() {
		setIsLarge(false);
	}

	function handleGoToNext() {
		if (index + 1 > otherSources.length - 1) return;
		setSrc(otherSources[index + 1]);
	}

	function handleGoToPrev() {
		if (index - 1 < 0) return;
		setSrc(otherSources[index - 1]);
	}

	/** Functions */
	function handleEsc(event: React.KeyboardEvent<HTMLDivElement>) {
		event.stopPropagation();
	}

	/** Effects */
	useEffect(() => {
		const _src = findChildrenSrc();
		if (!_src) return;
		setSrc(_src);
	}, [renderId]);

	useEffect(() => {
		if (!isLarge) return;
		async function fetchOtherSources() {
			try {
				const gallery = document.getElementById(lightboxId ?? LIGHTBOX_ID);
				if (!gallery) throw new Error("Something went wrong.");
				const images = Array.from(gallery.querySelectorAll("img"));
				const otherSources = images
					.map((img) => img.getAttribute("src"))
					.filter(Boolean) as string[];
				if (src) {
					const index = otherSources.indexOf(src);
					setIndex(index);
				}
				setOtherSources(otherSources);
			} catch (error) {
				console.error(`Couldnt find Lightbox ID: ${LIGHTBOX_ID}`, error);
			}
		}
		fetchOtherSources();
	}, [src, isLarge]);

	/** Render */
	return (
		<div style={{ overflow: "hidden", height: "100%" }}>
			<Box
				onKeyUp={handleEsc}
				height="100%"
				id={`base-image-${renderId}`}
				ref={baseImageRef}
				onClick={onOpenLargeImage}
				overflow="hidden"
				objectFit="contain"
			>
				{children}
			</Box>
			{isLarge && src && (
				<Portal>
					<Box
						position="fixed"
						zIndex={9999}
						top={0}
						left={0}
						right={0}
						bottom={0}
						backgroundColor="black"
						opacity={0.75}
						cursor="pointer"
						onClick={onClose}
					/>
					<Grid
						zIndex={10000}
						position="fixed"
						top={0}
						left={0}
						right={0}
						bottom={0}
						maxH="100vh"
						templateRows="6fr 1fr"
						placeItems="center"
						gap={12}
						py={12}
					>
						<Center
							width="100%"
							height="100%"
							onClick={onClose}
						>
							<div
								style={{
									height: "100%",
									width: "100%",
									backgroundImage: `url(${src})`,
									backgroundRepeat: "no-repeat",
									backgroundSize: "contain",
									backgroundPosition: "center",
								}}
							/>
						</Center>
						<HStack
							overflowX="scroll"
							height="100%"
							width="100%"
							onClick={onClose}
							px={12}
						>
							{otherSources.map((source) => (
								<Box
									onClick={(e) => {
										e.stopPropagation();
										setSrc(source);
									}}
									key={source}
									style={{
										height: "100%",
										aspectRatio: "1",
										backgroundImage: `url(${source})`,
										backgroundRepeat: "no-repeat",
										backgroundSize: "cover",
										cursor: "pointer",
										border:
											source === src
												? "2px solid white"
												: "2px solid transparent",
									}}
								/>
							))}
						</HStack>
					</Grid>
				</Portal>
			)}
		</div>
	);
};
