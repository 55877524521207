import type { BaustelleYearlyStatement } from "@msuite/katana";
import { Box, Divider, Label, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { Response } from "./reponse";

/** Props Interface */
interface ResponsesProps {
	responses: Array<BaustelleYearlyStatement>;
}

export const Responses: FC<ResponsesProps> = ({ responses }) => {
	if ((responses?.length || 0) === 0) return null;

	/** Render */
	return (
		<VStack
			align="stretch"
			spacing={0}
		>
			<Box mb={2}>
				<Divider />
			</Box>
			<Label>Antworten</Label>
			{responses.map((r) => (
				<Response
					response={r}
					key={r.id}
				/>
			))}
		</VStack>
	);
};
