import { BlankSlate, ListContainer } from "@msuite/picasso";
import type { FC } from "react";
import { TbMoodCheck } from "react-icons/tb";
import { CallNoteListItem } from "./call-note-list-item";
import { useProcessCallNotesModalContext } from "./context";

export const List: FC = () => {
	/** Context */
	const { callNotes } = useProcessCallNotesModalContext();

	/** Render */
	return (
		<ListContainer heading="Gesprächsnotizen">
			{!callNotes?.length && (
				<BlankSlate
					icon={<TbMoodCheck />}
					heading="Alles erledigt!"
					subtext=""
				/>
			)}
			{callNotes.map((callNote) => (
				<CallNoteListItem
					callNote={callNote}
					key={callNote.id}
				/>
			))}
		</ListContainer>
	);
};
