import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { db } from "@/core";
import { TicketSchema } from "@/forms/tickets-form/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { type Ticket, type TicketTypes, generateId } from "@msuite/katana";
import { Box, Collapse, Grid, Input, Select, toast } from "@msuite/picasso";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { TbExternalLink } from "react-icons/tb";

/** Props Interface */
interface QuickAddTicketProps {
	isOpen: boolean;
	onClose: () => void;
}

const ticketTypes: TicketTypes[] = [
	"Auf",
	"Ab",
	"Um",
	"Abholung",
	"Anlieferung",
	"Hinweis",
];

export const QuickAddTicket: FC<QuickAddTicketProps> = ({
	isOpen,
	onClose,
}) => {
	/** Context */
	const { constructionSiteId, editConstructionSiteModal } =
		useConstructionSiteCardContext();

	/** Hooks */
	const { register, handleSubmit, reset, getValues } = useForm<Ticket>({
		resolver: yupResolver(TicketSchema) as any,
		defaultValues: {
			id: generateId(),
			type: "Auf",
			ticket: "",
		},
	});

	/** Functions */
	async function onSubmit(ticket: Ticket) {
		try {
			const constructionSiteRef = doc(db, `baustellen/${constructionSiteId}`);
			await updateDoc(constructionSiteRef, {
				tickets: arrayUnion(ticket),
			});
			handleReset();
			onClose();
			toast.success("Ticket hinzugefügt");
		} catch (error) {
			console.error(error);
		}
	}

	function onReject() {
		toast.error("Ticket konnte nicht hinzugefügt werden");
	}

	function handleReset() {
		reset({
			id: generateId(),
			type: "Auf",
			ticket: "",
		});
	}

	function handleOnExpand() {
		localStorage.setItem("construction-site-edit-initial-ticket", "new-ticket");
		localStorage.setItem(
			"construction-site-edit-initial-ticket-data",
			JSON.stringify(getValues()),
		);
		editConstructionSiteModal.onOpen();
		onClose();
		// hack to put the reset function in the next event loop
		setTimeout(reset, 0);
	}

	/** Render */
	return (
		<Collapse in={isOpen}>
			<form onSubmit={(e) => handleSubmit(onSubmit, onReject)(e)}>
				<Grid
					templateColumns="max-content 1fr"
					pt={2}
					px="1px"
					pb={2}
					columnGap={2}
				>
					<Select {...register("type")}>
						{ticketTypes.map((type) => (
							<option
								key={type}
								value={type}
							>
								{type}
							</option>
						))}
					</Select>
					<Input
						{...register("ticket")}
						placeholder="z.B. SL100 Hofseite"
						rightIcon={
							<Box
								onClick={handleOnExpand}
								cursor="pointer"
								pointerEvents="all"
							>
								<TbExternalLink />
							</Box>
						}
					/>
				</Grid>
			</form>
		</Collapse>
	);
};
