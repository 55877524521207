import { Box, Center, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbFile } from "react-icons/tb";

export const TimelineIconLine: FC = () => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Box
			width="100%"
			position="relative"
			pb="20px"
		>
			<Center
				backgroundColor={colors.whiteAdjusted}
				width="50%"
				rounded="full"
				borderWidth={1}
				borderStyle="dashed"
				position="relative"
				zIndex={2}
				style={{
					aspectRatio: "1/1",
				}}
			>
				<TbFile />
			</Center>
			<Box
				position="absolute"
				left="25%"
				width="0"
				top="0"
				borderLeftWidth={1}
				height="100%"
				zIndex={1}
			/>
		</Box>
	);
};
