import { db } from "@/core";
import type { Employee } from "@msuite/katana";
import { DocumentString, FormControl, FormLabel, Grid } from "@msuite/picasso";
import type { FC } from "react";
import { useCallNoteOverviewContext } from "./context";

export const OverviewAnswerToSelect: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Render */
	return (
		<Grid
			templateColumns="1fr"
			gridRowGap={6}
			gridColumnGap={6}
		>
			<FormControl>
				<FormLabel>Ansprechpartner</FormLabel>
				<DocumentString<Employee>
					db={db}
					path={`mitarbeiter/${callNote.answerTo}`}
					fields={["vorname", "nachname"]}
				/>
			</FormControl>
		</Grid>
	);
};
