import { Tag } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface AssignLineTagProps {
	assignName: string;
}

export const AssignLineTag: FC<AssignLineTagProps> = ({ assignName }) => {
	/** Render */
	return (
		<Tag
			colorScheme="emerald"
			variant="solid"
		>
			{assignName}
		</Tag>
	);
};
