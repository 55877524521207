import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { usePlanContext } from "@/context/plan";
import { useTicketCardContext, useTicketsContext } from "@/context/ticket-card";
import { db } from "@/core";
import { getDailyReferenceString } from "@/sites/plan/dailies/daily/use-daily";
import {
	type DailyColonPlan,
	type DailyColonPlanConstructionSite,
	wait,
} from "@msuite/katana";
import type { DropdownMenuOptions } from "@msuite/picasso";
import { HStack, Text, toast, useUIContext } from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import { useCallback, useMemo } from "react";
import { IoInformation } from "react-icons/io5";
import { TbArrowRight } from "react-icons/tb";
import { QuickAssignItemConstructionSite } from "./quick-assign-colon-construction-site";
import { QuickAssignItemColon } from "./quick-assign-item-colon";

function indexToReadable(index: number) {
	switch (index) {
		case 0:
			return "Als erstes";
		case 1:
			return "Als zweites";
		case 2:
			return "Als drittes";
		case 3:
			return "Als viertes";
		case 4:
			return "Als fünftes";
		case 5:
			return "Als sechstes";
		case 6:
			return "Als siebtes";
		case 7:
			return "Als achtes";
		case 8:
			return "Als neuntes";
		case 9:
			return "Als zehntes";
		case 10:
			return "Als elftes";
		case 11:
			return "Als zwölftes";
		default:
			return `Als ${index + 1}.`;
	}
}

export function useQuickAssign() {
	/** Context */
	const { colors } = useUIContext();
	const { activeDailyColons, activeDate, weeklyReference } = usePlanContext();
	const { ticket, handleOnDeleteTicketDate } = useTicketCardContext();
	const { withMultiSelect, constructionSiteId } =
		useConstructionSiteCardContext();
	const { selectedTicketIndices, setSelectedTicketIndices, filteredTickets } =
		useTicketsContext();
	const dailyReference = getDailyReferenceString?.(weeklyReference, activeDate);

	/** Functions */
	function onCloseDropdownMenu() {
		document.dispatchEvent(new KeyboardEvent("keydown", { key: "Escape" }));
	}

	const handleFocusColon = useCallback(
		(colon: DailyColonPlan) => {
			try {
				const element = document.getElementById(
					`${activeDate.format("YYYY-MM-DD")}-${colon.id}`,
				);
				if (!element) return;
				(element as any)?.scrollIntoViewIfNeeded(true);
			} catch (error) {
				console.error(error);
			}
		},
		[activeDate],
	);

	const assignTicket = useCallback(
		async (ticketIds: string[], colon: DailyColonPlan, atIndex?: number) => {
			try {
				const colonRef = doc(db, `${dailyReference}/kolonnen/${colon.id}`);
				if (!constructionSiteId)
					throw new Error("No construction site or ticket id");
				const appendTickets = [];
				for (const ticketId of ticketIds) {
					const appendTicket: DailyColonPlanConstructionSite = {
						id: constructionSiteId,
						ticket: ticketId,
					};
					appendTickets.push(appendTicket);
				}
				const newConstructionSites = [...(colon.baustellen ?? [])];

				if (atIndex !== undefined) {
					newConstructionSites.splice(atIndex, 0, ...appendTickets);
				} else {
					newConstructionSites.push(...appendTickets);
				}

				updateDoc(colonRef, {
					baustellen: newConstructionSites,
				});
				onCloseDropdownMenu();
			} catch (error) {
				console.error(error);
				toast.error("Fehler beim Zuweisen des Tickets");
			} finally {
				await wait(500);
				handleFocusColon(colon);
			}
		},
		[constructionSiteId, dailyReference, handleFocusColon],
	);

	const handleMultiSelect = useCallback(
		async (colon: DailyColonPlan, atIndex?: number) => {
			console.log("RUNNING THIS");
			if (withMultiSelect && selectedTicketIndices?.length > 0) {
				const ticketIds = filteredTickets
					.map((ticket) => ticket.id)
					.filter((_, index) => selectedTicketIndices.includes(index));
				await Promise.all([
					assignTicket(ticketIds, colon, atIndex),
					handleOnDeleteTicketDate(ticketIds, "both", true),
				]);
			} else {
				await Promise.all([
					assignTicket([ticket.id], colon, atIndex),
					handleOnDeleteTicketDate([ticket.id], "both", true),
				]);
			}
			setSelectedTicketIndices([]);
		},
		[
			assignTicket,
			filteredTickets,
			selectedTicketIndices,
			ticket.id,
			withMultiSelect,
			setSelectedTicketIndices,
		],
	);

	/** Render */
	const createQuickAssignOptions = useCallback(
		(colons: DailyColonPlan[]): DropdownMenuOptions => {
			const options: DropdownMenuOptions = colons.map((colon) => {
				return {
					label: "",
					renderAs: () => <QuickAssignItemColon colon={colon} />,
					icon: <IoInformation />,
					onClick: () => {
						handleMultiSelect(colon);
					},
					subOptions: colon.baustellen?.flatMap((dailyConstructionSite, i) => {
						return [
							{
								label: indexToReadable(i),
								onClick: () => handleMultiSelect(colon, i),
								renderAs: () => (
									<HStack spacing={2}>
										<TbArrowRight />
										<Text
											fontWeight="medium"
											fontSize="xs"
										>
											{indexToReadable(i)}
										</Text>
									</HStack>
								),
							},
							{
								label: "",
								itemBackgroundColor: colors.grayLight,
								renderAs: () => (
									<QuickAssignItemConstructionSite
										dailyConstructionSite={dailyConstructionSite}
									/>
								),
								icon: <p>{i}</p>,
								withBottomBorder: true,
								withTopBorder: true,
								isDisabled: true,
							},
							i === (colon.baustellen?.length ?? 0) - 1
								? {
										label: indexToReadable(i + 1),
										onClick: () => handleMultiSelect(colon, i + 1),
										renderAs: () => (
											<HStack spacing={2}>
												<TbArrowRight />
												<Text
													fontWeight="medium"
													fontSize="xs"
												>
													{indexToReadable(i + 1)}
												</Text>
											</HStack>
										),
									}
								: undefined,
						].filter(Boolean) as DropdownMenuOptions;
					}),
				};
			});
			return options;
		},
		[handleMultiSelect, colors.grayLight],
	);

	const options = useMemo(() => {
		return createQuickAssignOptions(activeDailyColons);
	}, [activeDailyColons, createQuickAssignOptions]);

	return { options };
}
