import { Box, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbFile, TbGif, TbJpg, TbPdf, TbPng, TbSvg } from "react-icons/tb";

/** Props Interface */
interface FileIconProps {
	contentType: string;
}

function contentTypeToFileIcon(contentType: string) {
	switch (contentType) {
		case "application/pdf":
			return <TbPdf />;
		case "image/png":
			return <TbJpg />;
		case "image/jpeg":
			return <TbPng />;
		case "image/jpg":
			return <TbJpg />;
		case "image/gif":
			return <TbGif />;
		case "image/svg+xml":
			return <TbSvg />;
		case "image/svg":
			return <TbSvg />;
		default:
			return <TbFile />;
	}
}

export const FileIcon: FC<FileIconProps> = ({ contentType }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Box
			fontSize="3xl"
			color={colors.gray}
		>
			{contentTypeToFileIcon(contentType)}
		</Box>
	);
};
