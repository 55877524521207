import type { ISurveyQuestion } from "@msuite/katana";
import { createContext } from "react";

/** Interface */
interface IQuestionContext {
	question: ISurveyQuestion | undefined;
}

/** Context */
export const QuestionContext = createContext<IQuestionContext>({
	question: undefined,
});
