import { type FC, Fragment } from "react";
import { BaseGrid } from "./base-grid";
import { useDatePickerContext } from "./context";
import { DayItem } from "./day-item";
import { WeekItem } from "./week-item";

export const CalendarBody: FC = () => {
	/** Context */
	const { currentMonth } = useDatePickerContext();
	const numberOfWeeks = currentMonth.getWeeksInMonth();

	/** Render */
	return (
		<BaseGrid>
			{Array.from({ length: numberOfWeeks }).map((_, weeklyIndex) => {
				const week = currentMonth
					.clone()
					.startOf("month")
					.add(weeklyIndex, "week");

				return (
					<Fragment key={week.format("WW-YYYY")}>
						<WeekItem
							week={week}
							tabIndex={weeklyIndex * 7 + 1 + 0}
						/>
						{Array.from({ length: 7 }).map((_, dailyIndex) => {
							const date = week.clone().startOf("week").add(dailyIndex, "day");

							return (
								<DayItem
									tabIndex={weeklyIndex * 7 + dailyIndex + 1}
									key={date.format("YYYY-MM-DD")}
									week={week}
									date={date}
								/>
							);
						})}
					</Fragment>
				);
			})}
		</BaseGrid>
	);
};
