import { Box, Tooltip, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { IoInformation } from "react-icons/io5";

/** Props Interface */
interface HintProps {
	hint: string;
}

export const Hint: FC<HintProps> = ({ hint }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Tooltip label={hint}>
			<Box
				color={colors.blue}
				cursor="pointer"
			>
				<IoInformation />
			</Box>
		</Tooltip>
	);
};
