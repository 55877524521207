import type { Moment } from "@msuite/katana";
import { useHover, useToken, useUIContext } from "@msuite/picasso";
import { motion } from "framer-motion";
import { type FC, useRef } from "react";
import { useDatePickerContext } from "./context";
import { FocusElement } from "./focus-element";
import { type BaseItemMode, getDateFormatFromMode } from "./handlers";
import { HintElement } from "./hint-element";

/** Props Interface */
interface BaseItemProps {
	moment: Moment;
	mode: BaseItemMode;
	tabIndex: number;
	children: string | number;
	isInactive: boolean;
	alignLeft?: boolean;
	hasAccent?: boolean;
	hasHint?: boolean;
}

export const BASE_ITEM_BORDER_RADIUS = "6px";

export const BaseItem: FC<BaseItemProps> = ({
	children,
	isInactive,
	hasHint,
	hasAccent,
	moment,
	mode,
}) => {
	/** Context */
	const { currentValue, handleOnSelect, renderId, minValue } =
		useDatePickerContext();
	const { colors } = useUIContext();

	/** Constants */
	const dateFormat = getDateFormatFromMode(mode);
	const dateString = moment.format(dateFormat);
	const isFocused = currentValue === dateString;
	const momentType = mode === "day" ? "date" : mode;
	const isBeforeMinValue = moment.isBefore(minValue, momentType);

	/** Tokens */
	const fontColorAccent = useToken("colors", colors.brand);

	/** Hooks */
	const ref = useRef() as React.MutableRefObject<HTMLButtonElement>;
	const { isHovered } = useHover(ref);

	/** Functions */
	function handleOnClick() {
		if (isBeforeMinValue) return;
		handleOnSelect(moment, mode);
	}

	/** Render */
	return (
		<motion.button
			id={`${renderId}-${dateString}`}
			ref={ref as any}
			onClick={handleOnClick}
			type="button"
			style={{
				cursor: isBeforeMinValue ? "not-allowed" : "pointer",
				color: hasAccent ? fontColorAccent : undefined,
				opacity: (isInactive || isBeforeMinValue) && !isFocused ? 0.25 : 1,
				aspectRatio: "1",
				width: "35px",
				display: "flex",
				alignItems: "center",
				placeSelf: "center",
				justifyContent: "center",
				position: "relative",
				borderRadius: BASE_ITEM_BORDER_RADIUS,
			}}
		>
			{hasHint && <HintElement />}
			{isHovered && !isFocused && !isBeforeMinValue && (
				<FocusElement
					mode="hover"
					parentMode={mode}
					parentId={dateString}
					parentDateFormat={dateFormat}
					parentDate={moment}
				/>
			)}
			{isFocused && !isBeforeMinValue && (
				<FocusElement
					mode="focus"
					parentMode={mode}
					parentId={dateString}
					parentDateFormat={dateFormat}
					parentDate={moment}
				/>
			)}
			<motion.span
				style={{ position: "relative", zIndex: 2 }}
				animate={{ scale: isFocused || isHovered ? 1.15 : 1 }}
			>
				{children}
			</motion.span>
		</motion.button>
	);
};
