import { SurveyCard } from "@/components/survey";
import { HandleSurveyModalContext } from "@/context/handle-surveys-modal";
import type { ISurvey } from "@msuite/katana";
import { VStack } from "@msuite/picasso";
import { type FC, useContext } from "react";

export const SurveyList: FC = () => {
	/** Context */
	const { surveys, setSelectedSurvey } = useContext(HandleSurveyModalContext);

	/** Functions */
	const selectSurvey = (survey: ISurvey) => {
		setSelectedSurvey(survey);
	};

	/** Render */
	return (
		<VStack
			overflow="auto"
			align="stretch"
		>
			{surveys?.map((survey) => (
				<SurveyCard
					survey={survey}
					key={survey?.id}
					onClick={() => selectSurvey(survey)}
				/>
			))}
		</VStack>
	);
};
