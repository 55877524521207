import { db } from "@/core";
import type { ConstructionSite } from "@msuite/katana";
import {
	DocumentString,
	FormControl,
	FormLabel,
	HStack,
	Text,
} from "@msuite/picasso";
import type { FC } from "react";
import { useCallNoteOverviewContext } from "./context";

export const OverviewReasonConstructionSite: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Fallback */
	if (callNote.reason.type !== "construction-site") return null;

	/** Render */
	return (
		<FormControl>
			<FormLabel>Bauvorhaben</FormLabel>
			<HStack spacing={1}>
				<DocumentString<ConstructionSite>
					path={`baustellen/${callNote.reason.constructionSiteId}`}
					fields={["nummer", "bauvorhaben"]}
					transformers={[
						{ field: "nummer", transformer: (value) => `${value} /` },
					]}
					db={db}
				/>
				{callNote.reason.isUnsafe && <Text>(unsichere Angabe)</Text>}
			</HStack>
		</FormControl>
	);
};
