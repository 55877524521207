import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { Flex, ManagerBadge } from "@msuite/picasso";
import type { FC } from "react";

export const ManagerStack: FC = () => {
	/** Context */
	const { constructionSite } = useConstructionSiteCardContext();

	/** Render */
	return (
		<Flex
			flexWrap="wrap"
			width="30%"
			justify="flex-end"
			gap={2}
		>
			{constructionSite?.currentDeputies?.map((managerId) => (
				<ManagerBadge
					isDeputy
					id={managerId}
					key={managerId}
				/>
			))}
			{constructionSite?.bauleiter?.map((managerId) => (
				<ManagerBadge
					id={managerId}
					key={managerId}
				/>
			))}
		</Flex>
	);
};
