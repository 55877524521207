import { ax } from "@/core";
import type {
	ConstructionSite,
	Employee,
	ISiteManagersApiResponse,
} from "@msuite/katana";
import {
	Box,
	FormControl,
	FormLabel,
	HStack,
	IconButton,
	Select,
	VStack,
	useApi,
} from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { TbChevronDown, TbTrashFilled } from "react-icons/tb";
import { FormError } from "./schema";

export type ManagerType = "bauleiter" | "currentDeputies";

/** Props Interface */
interface ManagersHandlerProps {
	handleManagersFrom?: ManagerType;
	selectableFilterFn?: (manager: Employee) => boolean;
}

const labels: Record<ManagerType, string> = {
	bauleiter: "Bauleiter",
	currentDeputies: "Stellvertreter",
};

export const ManagersHandler: FC<ManagersHandlerProps> = ({
	handleManagersFrom = "bauleiter",
	selectableFilterFn = () => true,
}) => {
	/** Context */
	const methods = useFormContext<ConstructionSite>();
	const managers = methods.watch(handleManagersFrom);

	/** Hooks */
	const { data } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees/site-managers",
		dependencies: ["site-managers"],
	});
	const selectableManagers = data?.employees.filter(selectableFilterFn) ?? [];

	/** Functions */
	function handleSelectManager(event: React.ChangeEvent<HTMLSelectElement>) {
		const currentManagers = methods.getValues(handleManagersFrom);
		const newManagers = [...(currentManagers ?? []), event.target.value];
		methods.setValue(handleManagersFrom, newManagers);
	}

	function findManager(
		managerId: string,
		transformer: (manager: Employee) => string,
	) {
		const manager = data?.employees.find((manager) => manager.id === managerId);
		if (!manager) return "N.A.";
		return transformer(manager);
	}

	function removeManagerAtIndex(index: number) {
		const currentManagers = methods.getValues(handleManagersFrom);
		const newManagers = [...(currentManagers ?? [])];
		newManagers.splice(index, 1);
		methods.setValue(handleManagersFrom, newManagers);
	}

	function changeManagerAtIndex(
		event: React.ChangeEvent<HTMLSelectElement>,
		index: number,
	) {
		const currentManagers = methods.getValues(handleManagersFrom);
		const newManagers = [...(currentManagers ?? [])];
		newManagers[index] = event.target.value;
		methods.setValue(handleManagersFrom, newManagers);
	}

	/** Render */
	return (
		<FormControl>
			<FormLabel>{labels[handleManagersFrom]}</FormLabel>
			<VStack spacing={4}>
				{managers?.map((managerId, index) => (
					<Box
						position="relative"
						key={managerId}
					>
						<Select
							placeholder={findManager(
								managerId,
								(manager) => `${manager.vorname} ${manager.nachname}`,
							)}
							onChange={(e) => changeManagerAtIndex(e, index)}
							title={`${labels[handleManagersFrom]} wechseln`}
							icon={<div />}
							position="relative"
						>
							{selectableManagers.map((employee) => (
								<option
									disabled={managers?.includes(employee.id)}
									key={employee.id}
									value={employee.id}
								>
									{employee.vorname} {employee.nachname}
								</option>
							))}
						</Select>
						<HStack
							position="absolute"
							height="100%"
							right="0"
							pr={2}
							top="0"
							spacing={2}
						>
							<TbChevronDown />
							<IconButton
								variant="ghost"
								aria-label={`${labels[handleManagersFrom]} entfernen`}
								icon={<TbTrashFilled />}
								colorScheme="red"
								_hover={{ backgroundColor: "transparent" }}
								onClick={() => removeManagerAtIndex(index)}
							/>
						</HStack>
					</Box>
				))}
				<Select
					placeholder={`${labels[handleManagersFrom]} auswählen`}
					onChange={handleSelectManager}
					title={`${labels[handleManagersFrom]} auswählen`}
					value=""
				>
					{selectableManagers.map((employee) => (
						<option
							key={employee.id}
							value={employee.id}
							disabled={managers?.includes(employee.id)}
						>
							{employee.vorname} {employee.nachname}
						</option>
					))}
				</Select>
			</VStack>
			<FormError name={handleManagersFrom} />
		</FormControl>
	);
};
