import { db, queryClient } from "@/core";
import { CallNoteOverview } from "@/forms/call-note-form";
import { moment, type CallNote } from "@msuite/katana";
import {
	Button,
	HStack,
	Heading,
	ModalControllerContainer,
	VStack,
	toast,
	useAsyncModal,
	useAuthContext,
	useDocument,
} from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import { Fragment, useState, type FC } from "react";
import { useProcessCallNotesModalContext } from "./context";

export const CallNoteController: FC = () => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Context */
	const { userId } = useAuthContext();
	const { selectedCallNote, setSelectedCallNote, refetch } =
		useProcessCallNotesModalContext();

	/** Hooks */
	const asyncModal = useAsyncModal();
	const { data: callNote } = useDocument<CallNote>(db, {
		path: `callNotes/${selectedCallNote?.id}`,
		subscribe: true,
	});

	/** Functions */
	function handleOnDismiss() {
		setSelectedCallNote(undefined);
	}

	async function handleOnMarkAsAnswered() {
		try {
			setIsLoading(true);

			if (!userId) throw new Error("user_uid is not defined");

			const isConfirmed = await asyncModal({
				headerText: "Rückruf als 'Erledigt' markieren",
				bodyText:
					"Möchten Sie diese Rückrufbitte wirklich als beantwortet markieren?",
			});
			if (!isConfirmed) return;
			const callNoteRef = doc(db, `callNotes/${selectedCallNote?.id}`);

			const updateObj: Partial<CallNote> = {
				answeredAt: moment().toISOString(),
				answeredBy: userId,
				state: "closed",
			};

			await updateDoc(callNoteRef, { ...updateObj });
			await refetch();
			toast.success("Rückrufbitte wurde als beantwortet markiert.");
			await queryClient.refetchQueries({
				queryKey: ["dashboard", "call-notes"],
				exact: false,
			});
		} catch (error) {
			console.error(error);
			toast.error("Es ist ein Fehler aufgetreten.");
		} finally {
			setIsLoading(false);
		}
	}

	/** Computed */
	const isAnswered = !!callNote?.answeredAt;

	/** Render */
	return (
		<ModalControllerContainer>
			<HStack
				padding={6}
				borderBottomWidth={1}
			>
				<Heading>
					{callNote?.caller.firstName} {callNote?.caller.lastName}
				</Heading>
			</HStack>
			<VStack
				flex={1}
				flexGrow={0}
				overflowY="scroll"
				padding={6}
			>
				{callNote && (
					<CallNoteOverview
						callNote={callNote}
						withCommentBox
					/>
				)}
			</VStack>
			<HStack
				padding={6}
				borderTopWidth={1}
				justify="flex-end"
			>
				{isAnswered ? (
					<Button onClick={handleOnDismiss}>Fertig</Button>
				) : (
					<Fragment>
						<Button
							variant="ghost"
							onClick={handleOnDismiss}
						>
							abbrechen
						</Button>
						<Button
							onClick={handleOnMarkAsAnswered}
							isLoading={isLoading}
						>
							Als beantwortet markieren
						</Button>
					</Fragment>
				)}
			</HStack>
		</ModalControllerContainer>
	);
};
