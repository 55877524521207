import { QuestionContext } from "@/context/survey-question";
import { Heading } from "@msuite/picasso";
import { type FC, useContext, useMemo } from "react";

export const Prompt: FC = () => {
	/** Context */
	const { question } = useContext(QuestionContext);

	/** Handlers */
	const prompt = useMemo(() => {
		if (!question?.repeatKey) return question?.prompt || "";
		return question.prompt?.replaceAll("$key", question.repeatKey) || "";
	}, [question?.prompt, question?.repeatKey]);

	/** Render */
	return <Heading noOfLines={2}>{prompt}</Heading>;
};
