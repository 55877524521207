import type { EmployeeWithAdditionalData } from "@msuite/katana";
import { moment } from "@msuite/katana";
import { Link, Redirector } from "@msuite/picasso";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<EmployeeWithAdditionalData>();

export const columns = [
	columnHelper.accessor("employeeNr", {
		header: () => "Nr.",
		cell: (cell) => cell.row.original.employeeNr || "--",
		meta: {
			width: "10%",
		},
	}),
	columnHelper.accessor("nachname", {
		header: () => "Name",
		cell: (cell) =>
			`${cell.row.original.nachname}, ${cell.row.original.vorname}`,
		meta: {
			width: "35%",
		},
	}),
	columnHelper.accessor("email", {
		header: () => "E-Mail",
		cell: (cell) =>
			cell.row.original.email ? (
				<Link href={`mailto:${cell.row.original.email}`}>
					{cell.row.original.email}
				</Link>
			) : (
				"--"
			),
		meta: {
			width: "25%",
		},
	}),
	columnHelper.accessor("geburtsdatum", {
		header: () => "Geburtsdatum",
		cell: (cell) =>
			cell.row.original.geburtsdatum
				? `${moment(cell.row.original.geburtsdatum).format("L")} (${moment().diff(
						moment(cell.row.original.geburtsdatum),
						"years",
					)})`
				: "--",
		meta: {
			width: "15%",
		},
	}),
	columnHelper.display({
		id: "actions",
		header: () => "",
		cell: (cell) => (
			<Redirector link={`/personal/employees/${cell.row.original.id}`} />
		),
		meta: {
			width: "15%",
			isNumeric: true,
		},
	}),
];
