import { useAppContext } from "@/context/app";
import { ax, db } from "@/core";
import {
	ConstructionSiteForm,
	useConstructionSiteForm,
} from "@/forms/construction-site-form";
import { ConstructionSiteSchema } from "@/forms/construction-site-form/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import type { ConstructionSite, ConstructionSiteContact } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalTitle,
	SegmentedControl,
	VStack,
	toast,
	useCollection,
	useQueryClient,
} from "@msuite/picasso";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { type FC, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { addConstructionSiteToWeekly } from "util/construction-site";

/** Props Interface */
interface EditConstructionSiteModalProps {
	isOpen: boolean;
	onClose: () => void;
	constructionSite: ConstructionSite;
	shouldAddToWeek?: boolean;
}

export const EditConstructionSiteModal: FC<EditConstructionSiteModalProps> = ({
	isOpen,
	onClose,
	constructionSite,
	shouldAddToWeek,
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [generateNumber, setGenerateNumber] = useState<boolean>(false);

	/** Context */
	const { activeWorkspace } = useAppContext();

	/** Hooks */
	const qc = useQueryClient();
	const modalRef = useRef(null);
	const methods = useForm<ConstructionSite>({
		resolver: yupResolver(ConstructionSiteSchema) as any,
		reValidateMode: "onSubmit",
		defaultValues: constructionSite,
	});
	const {
		SEGMENTED_CONTROL_OPTIONS,
		currentView,
		setCurrentView,
		initialFocusTicketId,
	} = useConstructionSiteForm({
		isOpen,
		methods,
	});
	const constructionSiteId = constructionSite.id;

	/** Prefetch */
	const { isFetched: prefetchIsFetched } =
		useCollection<ConstructionSiteContact>(db, {
			path: "_contactBook",
			orderBy: "name",
			subscribe: false,
		});

	/** Functions */
	function handleOnClose() {
		handleReset();
		onClose();
	}

	async function handleOnSubmit(values: ConstructionSite) {
		try {
			setIsLoading(true);
			if (!constructionSite?.id) return;
			const constructionSiteRef = doc(db, `baustellen/${constructionSite.id}`);
			await updateDoc(constructionSiteRef, { ...values });
			if (!values.clientId)
				await updateDoc(constructionSiteRef, { clientId: deleteField() });
			await ax.patch(`/v2/construction-sites/${constructionSite.id}/geo-data`);
			if (shouldAddToWeek)
				await addConstructionSiteToWeekly(values, activeWorkspace);
			handleOnClose();
			await qc.invalidateQueries({
				queryKey: ["construction-sites"],
				exact: false,
			});
		} catch (error) {
			console.error(error);
			toast.error("Fehler beim Aktualisieren der Baustelle");
		} finally {
			setIsLoading(false);
		}
	}

	function handleReset() {
		setCurrentView("construction-site");
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit)}>
					<ModalContent
						maxW="60rem"
						minH="55vh"
					>
						<ModalCloseButton />
						<ModalHeader>
							<VStack>
								<ModalTitle>Baustelle bearbeiten</ModalTitle>
								<SegmentedControl
									options={SEGMENTED_CONTROL_OPTIONS}
									value={currentView}
									onChange={setCurrentView}
								/>
							</VStack>
						</ModalHeader>
						<ModalBody>
							{isOpen && prefetchIsFetched && (
								<ConstructionSiteForm
									modalRef={modalRef}
									isEdit
									currentView={currentView}
									initialFocusTicketId={initialFocusTicketId}
									generateNumber={generateNumber}
									setGenerateNumber={setGenerateNumber}
									constructionSiteId={constructionSiteId}
								/>
							)}
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={isLoading}
									type="submit"
								>
									Aktualisieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
