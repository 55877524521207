import { db } from "@/core";
import type { ConstructionSite } from "@msuite/katana";
import {
	ContentContainerBody,
	ContentContainerHeading,
	Heading,
	ModalControllerContainer,
	Text,
	TimesheetControllerForm,
	useDocument,
} from "@msuite/picasso";
import { useRef, type FC } from "react";
import { useProcessTimesheetsModalContext } from "./context";

export const Control: FC = () => {
	/** Context */
	const { selectedPublishEntry, setSelectedPublishEntry, refetch } =
		useProcessTimesheetsModalContext();

	/** Hooks */
	const footerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { data: constructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${selectedPublishEntry?.constructionSiteId}`,
	});

	/** Functions */
	function handleOnDismiss() {
		setSelectedPublishEntry(undefined);
	}

	async function handleOnComplete() {
		setSelectedPublishEntry(undefined);
		await refetch();
	}

	if (!selectedPublishEntry) return null;

	/** Render */
	return (
		<ModalControllerContainer>
			<ContentContainerHeading>
				<Heading>{constructionSite?.bauvorhaben}</Heading>
				<Text>{constructionSite?.nummer}</Text>
			</ContentContainerHeading>
			<ContentContainerBody>
				<TimesheetControllerForm
					entryId={selectedPublishEntry?.entry.id}
					subcontractorId={selectedPublishEntry?.subcontractorId}
					mode="control"
					footerRef={footerRef}
					onDismiss={handleOnDismiss}
					onComplete={handleOnComplete}
				/>
			</ContentContainerBody>
			<div ref={footerRef} />
		</ModalControllerContainer>
	);
};
