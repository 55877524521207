import { Center } from "@msuite/picasso";
import { type StorageReference, getDownloadURL } from "firebase/storage";
import { type FC, useEffect, useState } from "react";
import { FileIcon } from "./file-icon";
import { FileImage } from "./file-image";

/** Props Interface */
interface FileIconContainerProps {
	file: File;
	storageRef?: StorageReference;
}

export const FileIconContainer: FC<FileIconContainerProps> = ({
	file,
	storageRef,
}) => {
	/** Constants */
	const [isImage, setIsImage] = useState<boolean>(file.type.includes("image"));
	const [imageSize, setImageSize] = useState<{
		width: number;
		height: number;
	} | null>(null);
	const [fileDownloadUrl, setFileDownloadUrl] = useState<string>(
		"https://via.placeholder.com/150",
	);
	const isFile = !isImage;

	/** Functions */
	function handleOnOpenDocument() {
		window.open(fileDownloadUrl, "_blank");
	}

	useEffect(() => {
		async function fetchFile() {
			if (storageRef) {
				const downloadUrl = await getDownloadURL(storageRef);
				const image = new Image();
				image.src = downloadUrl;
				image.onload = () => {
					setIsImage(true);
					setImageSize({ width: image.width, height: image.height });
				};
				setFileDownloadUrl(downloadUrl);
			} else {
				const fileUrl = URL.createObjectURL(file);
				setFileDownloadUrl(fileUrl);
				setIsImage(file.type.includes("image"));
			}
		}

		fetchFile();
	}, [storageRef, file]);

	/** Render */
	return (
		<Center
			borderRightWidth={1}
			backgroundColor="neutral.100"
			width="100%"
			height="100%"
			position="relative"
			zIndex={5}
			onClick={isFile ? handleOnOpenDocument : undefined}
			cursor="pointer"
		>
			{isImage && (
				<FileImage
					src={fileDownloadUrl}
					imageSize={imageSize}
				/>
			)}
			{isFile && <FileIcon contentType={file.type} />}
		</Center>
	);
};
