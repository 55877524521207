import { QuestionContext } from "@/context/survey-question";
import { Box, Text } from "@msuite/picasso";
import { type FC, useContext, useMemo } from "react";

export const Description: FC = () => {
	/** Context */
	const { question } = useContext(QuestionContext);

	/** Handlers */
	const description = useMemo(() => {
		if (!question?.repeatKey) return question?.description || "";

		return (
			question.description?.replaceAll(
				"$key",
				`<b>${question.repeatKey}</b>`,
			) || ""
		);
	}, [question?.description, question?.repeatKey]);

	/** Render */
	return (
		<Box my="1rem">
			<Text
				fontSize="lg"
				dangerouslySetInnerHTML={{ __html: description }}
				whiteSpace="pre-wrap"
			/>
		</Box>
	);
};
