import { BlankSlate, ListContainer } from "@msuite/picasso";
import type { FC } from "react";
import { TbMoodCheck } from "react-icons/tb";
import { useProcessTimesheetsModalContext } from "./context";
import { SubcontractorSection } from "./subcontractor-section";

export const List: FC = () => {
	/** Context */
	const { timesheetSections } = useProcessTimesheetsModalContext();

	/** Render */
	return (
		<ListContainer heading="Stundenzettel">
			{!timesheetSections?.length && (
				<BlankSlate
					icon={<TbMoodCheck />}
					heading="Alles erledigt!"
					subtext=""
				/>
			)}
			{timesheetSections.map((section) => (
				<SubcontractorSection
					key={section.subunternehmer_id}
					section={section}
				/>
			))}
		</ListContainer>
	);
};
