import type { RentListReason } from "@msuite/katana";
import {
	Box,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
} from "@msuite/picasso";
import type { FC } from "react";
import { IoHelp } from "react-icons/io5";

/** Props Interface */
interface ReasonPopoverProps {
	reason: RentListReason;
}

export const ReasonPopover: FC<ReasonPopoverProps> = ({ reason }) => {
	return (
		<Popover trigger="hover">
			<PopoverTrigger>
				<Box
					cursor="pointer"
					fontSize="sm"
					color="gray.500"
				>
					<IoHelp />
				</Box>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverHeader fontWeight="semibold">Abfragegrund</PopoverHeader>
				<PopoverBody>{rentListReasonToString(reason)}</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

function rentListReasonToString(reason: RentListReason) {
	switch (reason) {
		case "last-invoice-older-than-4-weeks":
			return "Die letzte Rechnung für dieses Bauvorhaben wurde vor über 4 Wochen geschrieben.";
		case "last-invoice-older-than-8-weeks":
			return "Die letzte Rechnung für dieses Bauvorhaben wurde vor über 8 Wochen geschrieben.";
		case "no-invoices":
			return "Es wurden bisher noch keine Rechnungen für dieses Bauvorhaben geschrieben.";
		case "no-timeline-entries":
			return "Es gibt bisher noch keine Timeline Einträge für dieses Bauvorhaben obwohl bereits eine Projekt-Nr. vergeben wurde.";
		default:
			return "N.A.";
	}
}
