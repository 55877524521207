import { SurveyContext } from "@/context/survey";
import { type FC, useContext } from "react";
import { FreetextQuestion } from "./freetext-question";
import { InstructionQuestion } from "./instruction-question";
import { MultipleChoiceQuestion } from "./multiple-choice-question";
import { NumberQuestion } from "./number-question";

export const QuestionSwitcher: FC = () => {
	/** Context */
	const { activeElement } = useContext(SurveyContext);

	if (activeElement?.questionType === "multiple_choice")
		return (
			<MultipleChoiceQuestion
				key={`${activeElement.questionId}${activeElement.repeatKey}`}
			/>
		);
	if (activeElement?.questionType === "freetext")
		return (
			<FreetextQuestion
				key={`${activeElement.questionId}${activeElement.repeatKey}`}
			/>
		);
	if (activeElement?.questionType === "number")
		return (
			<NumberQuestion
				key={`${activeElement.questionId}${activeElement.repeatKey}`}
			/>
		);
	if (activeElement?.questionType === "instruction")
		return (
			<InstructionQuestion
				key={`${activeElement.questionId}${activeElement.repeatKey}`}
			/>
		);

	/** Render */
	return <div />;
};
