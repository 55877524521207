import type { ConstructionSite } from "@msuite/katana";
import type { useDisclosure } from "@msuite/picasso";
import type { ConstructionSiteCardProps } from "components/card/construction-site-card/construction-site-card-content";
import { createContext, useContext } from "react";

/** Interface */
interface IConstructionSiteCardContext extends ConstructionSiteCardProps {
	constructionSite: ConstructionSite | undefined;
	isLoading: boolean;
	hasCurrentAssignment: boolean;
	editConstructionSiteModal: ReturnType<typeof useDisclosure>;
	userIsOwner: boolean;
	showAllActiveTickets: boolean;
	setShowAllActiveTickets: (showAllActiveTickets: boolean) => void;
	isArchived?: boolean;
}

/** Context */
export const ConstructionSiteCardContext =
	createContext<IConstructionSiteCardContext>(
		{} as IConstructionSiteCardContext,
	);

/** Hooks */
export const useConstructionSiteCardContext = () => {
	return useContext(ConstructionSiteCardContext);
};
