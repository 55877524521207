import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { usePlanContext } from "@/context/plan";
import { type DailyColonPlan, romanize } from "@msuite/katana";
import { Flex } from "@msuite/picasso";
import { type FC, useMemo } from "react";
import { AssignLineEmployee } from "./assign-line-employee";
import { AssignLineTag } from "./assing-line-tag";

interface AssignLineAcc {
	ownEmployees: string[];
	externalEmployees: string[];
	unnamedEmployees: string[];
}

function reduceEmployees(acc: AssignLineAcc, colon: DailyColonPlan) {
	const employeeId = colon.mitarbeiter?.at(0);
	if (!employeeId) {
		acc.unnamedEmployees.push(`${colon.name} ${romanize(colon.rating)}`);
		return acc;
	}
	if (colon.name === "Kolonne") {
		acc.ownEmployees.push(employeeId);
	} else {
		acc.externalEmployees.push(employeeId);
	}
	return acc;
}

export const AssignLine: FC = () => {
	/** Context */
	const { constructionSiteId } = useConstructionSiteCardContext();
	const { activeDailyColons } = usePlanContext();

	/** Effects */
	const assigns = useMemo(() => {
		const matched = activeDailyColons.filter((colon) =>
			colon.baustellen?.some((c) => c.id === constructionSiteId),
		);
		return matched.reduce(reduceEmployees, {
			ownEmployees: [],
			externalEmployees: [],
			unnamedEmployees: [],
		});
	}, [activeDailyColons, constructionSiteId]);

	if (!Object.values(assigns)?.flat?.()?.length) return null;

	/** Render */
	return (
		<Flex
			gap={2}
			pt={2}
			wrap="wrap"
		>
			{assigns.ownEmployees?.map((employeeId) => (
				<AssignLineEmployee
					employeeId={employeeId}
					key={employeeId}
				/>
			))}
			{assigns.externalEmployees?.map((employeeId) => (
				<AssignLineTag
					assignName={employeeId}
					key={employeeId}
				/>
			))}
			{assigns.unnamedEmployees?.map((assignName) => (
				<AssignLineTag
					assignName={assignName}
					key={assignName}
				/>
			))}
		</Flex>
	);
};
