import type { ConstructionSite } from "@msuite/katana";
import {
	FormControl,
	FormLabel,
	Grid,
	HStack,
	IconButton,
	Input,
	Label,
	Text,
	VStack,
	useUIContext,
} from "@msuite/picasso";
import type { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IoAdd, IoTrash } from "react-icons/io5";

export const TrimbleConnectAdder: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const methods = useFormContext<ConstructionSite>();
	const fieldArray = useFieldArray({
		control: methods.control,
		name: "trimbleConnect",
	});

	/** Functions */
	function handleOnAppend() {
		fieldArray.append({ name: "", trimbleLink: "" });
	}

	/** Render */
	return (
		<VStack spacing={2}>
			<HStack justifyContent="space-between">
				<Label>3D-Modell</Label>
				<IconButton
					onClick={handleOnAppend}
					size="xs"
					icon={<IoAdd />}
					aria-label="add"
				/>
			</HStack>
			<VStack>
				{fieldArray.fields.map((item, index) => (
					<Grid
						gridTemplateColumns="1fr 2fr min-content"
						key={item.id}
						gap={4}
					>
						<FormControl>
							<FormLabel>
								{index === 0 && <FormLabel>Name</FormLabel>}
								<Input
									{...methods.register(`trimbleConnect.${index}.name`)}
									id={`trimble-connect-name-${index}`}
									placeholder="3D-Modell"
									color={colors.black}
								/>
							</FormLabel>
						</FormControl>
						<FormControl>
							<FormLabel>
								{index === 0 && <FormLabel>Link</FormLabel>}
								<Input
									{...methods.register(`trimbleConnect.${index}.trimbleLink`)}
									placeholder="https://..."
									color={colors.black}
								/>
							</FormLabel>
						</FormControl>
						<FormControl>
							{index === 0 && <FormLabel>&nbsp;</FormLabel>}
							<IconButton
								onClick={() => fieldArray.remove(index)}
								alignSelf="flex-end"
								size="md"
								icon={
									<Text color="red.500">
										<IoTrash />
									</Text>
								}
								aria-label="remove"
							/>
						</FormControl>
					</Grid>
				))}
			</VStack>
		</VStack>
	);
};
