import type { DocumentComment } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const FormError = FormErrorMessage<DocumentComment>;

export const documentCommentSchema = yup.object().shape({
	comment: yup
		.string()
		.min(2, "Kommentar muss mindestens 2 Zeichen lang sein")
		.required("Kommentar ist ein Pflichtfeld"),
});
