import { db, storage } from "@/core";
import type { Offer } from "@msuite/katana";
import { generateId, moment } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useAuthContext,
} from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ScannedOfferForm } from "forms/scanned-offer-form";
import { type FC, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";

/** Props Interface */
interface EditOfferModalProps {
	isOpen: boolean;
	onClose: () => void;
	offer: Offer;
}

export const EditOfferModal: FC<EditOfferModalProps> = ({
	isOpen,
	onClose,
	offer,
}) => {
	/** Context */
	const { userId } = useAuthContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [files, setFiles] = useState<File[]>([]);

	/** Hooks */
	const methods = useForm<Offer>({
		defaultValues: offer,
	});
	const [currentFiles] = useWatch({
		control: methods.control,
		name: ["files"],
	});

	/** Functions */
	async function onSubmit(data: Offer) {
		try {
			setIsLoading(true);
			const offerRef = doc(db, `offers/${offer.offerId}`);
			data.updatedAt = moment().toISOString();
			data.updatedBy = userId ?? "";
			data.constructionSiteId = null;
			const filesBefore = methods.getValues("files") ?? [];

			const uploadPromises = files.map(async (file) => {
				if (filesBefore.includes(file.name)) return file.name;
				const storageRef = ref(
					storage,
					`offers/${generateId() + "_" + file.name}`,
				);
				const result = await uploadBytes(storageRef, file);
				return result.ref.name;
			});
			const uploadedFiles = await Promise.all(uploadPromises);

			data.files = uploadedFiles.map((fileName) => fileName);

			onClose();
			await updateDoc(offerRef, { ...data });
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleOnFilesChange(files: File[]) {
		setFiles(files);
	}

	function handleOnRemoveFile(fileIndex: number) {
		const newFiles = files.filter((_, index) => index !== fileIndex);
		setFiles(newFiles);
	}

	/** Effects */
	useEffect(() => {
		async function fetchFiles() {
			const filePromises =
				currentFiles?.map(async (fileString: string) => {
					const storageRef = ref(storage, `offers/${fileString}`);
					const downloadUrl = await getDownloadURL(storageRef);
					const raw = await fetch(downloadUrl);
					const blob = await raw.blob();
					const file = new File([blob], fileString, { type: blob.type });
					return file;
				}) ?? [];
			const files = await Promise.all(filePromises);
			setFiles(files);
		}

		fetchFiles();
	}, [currentFiles]);

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Angebot bearbeiten</ModalHeader>
						<ModalBody>
							<ScannedOfferForm
								files={files}
								onFilesChange={handleOnFilesChange}
								onRemoveFile={handleOnRemoveFile}
							/>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={onClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={isLoading}
									type="submit"
								>
									Aktualisieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
