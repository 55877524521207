import { Spacer } from "@msuite/picasso";
import type { FC } from "react";
import { Description } from "../description";
import { Prompt } from "../prompt";
import { QuestionStepper } from "../question-stepper";

export const InstructionQuestion: FC = () => {
	/** Render */
	return (
		<>
			<Prompt />
			<Description />
			<Spacer />
			<QuestionStepper onUpdate={async () => {}} />
		</>
	);
};
