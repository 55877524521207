import { FormControl, FormLabel, Grid, GridItem, Text } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import {
	CallerContactTypeLabels,
	CallerPositionLabels,
	CallerTitleLabels,
} from "../form/schema";
import { useCallNoteOverviewContext } from "./context";

export const OverviewCallerInformation: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Render */
	return (
		<Grid
			columnGap={6}
			rowGap={6}
			templateColumns="1fr 1fr"
		>
			<GridItem colSpan={2}>
				<FormLabel>Unternehmen</FormLabel>
				<Text>{callNote.caller.companyName || "--"}</Text>
			</GridItem>
			<FormControl>
				<FormLabel>Position</FormLabel>
				<Text>{CallerPositionLabels[callNote.caller.position]}</Text>
			</FormControl>
			<FormControl>
				<FormLabel>Anrede</FormLabel>
				<Text>{CallerTitleLabels[callNote.caller.title]}</Text>
			</FormControl>
			<FormControl>
				<FormLabel>Vorname</FormLabel>
				<Text>{callNote.caller.firstName || "--"}</Text>
			</FormControl>
			<FormControl>
				<FormLabel>Nachname</FormLabel>
				<Text>{callNote.caller.lastName || "--"}</Text>
			</FormControl>
			{callNote.caller.contact.map((contact, index) => (
				<Fragment key={contact.value}>
					<FormControl>
						{!index && <FormLabel>Kontaktart</FormLabel>}
						<Text>{CallerContactTypeLabels[contact.type]}</Text>
					</FormControl>
					<FormControl>
						{!index && <FormLabel>Kontakt</FormLabel>}
						<Text>{contact.value || "--"}</Text>
					</FormControl>
				</Fragment>
			))}
		</Grid>
	);
};
