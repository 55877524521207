import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { useTicketCardContext } from "@/context/ticket-card";
import { UnstyledIconButton, toast } from "@msuite/picasso";
import { type FC, useState } from "react";
import { TbCornerUpLeft } from "react-icons/tb";

export const TicketRemover: FC = () => {
	/** Context */
	const { onRemoveTicket, ticketIndexOverride } =
		useConstructionSiteCardContext();
	const { ticket, ticketIndex } = useTicketCardContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Functions */
	async function handleOnTicketRemove() {
		try {
			setIsLoading(true);
			await onRemoveTicket?.(ticket, ticketIndexOverride ?? ticketIndex);
		} catch (error) {
			console.error(error);
			toast.error("Ticket konnte nicht entfernt werden");
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<UnstyledIconButton
			placeSelf="center"
			justifySelf="center"
			alignSelf="center"
			icon={<TbCornerUpLeft />}
			aria-label="Ticket entfernen"
			size="sm"
			variant="ghost"
			rounded="full"
			isLoading={isLoading}
			onClick={handleOnTicketRemove}
			_hover={{ backgroundColor: "transparent" }}
		/>
	);
};
