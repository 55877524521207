import type { FC } from "react";
import { BaseGrid } from "./base-grid";
import { CalendarHeaderItem } from "./calendar-header-item";

export const CalendarHeader: FC = () => {
	return (
		<BaseGrid>
			<CalendarHeaderItem>KW</CalendarHeaderItem>
			<CalendarHeaderItem>Mo</CalendarHeaderItem>
			<CalendarHeaderItem>Di</CalendarHeaderItem>
			<CalendarHeaderItem>Mi</CalendarHeaderItem>
			<CalendarHeaderItem>Do</CalendarHeaderItem>
			<CalendarHeaderItem>Fr</CalendarHeaderItem>
			<CalendarHeaderItem>Sa</CalendarHeaderItem>
			<CalendarHeaderItem>So</CalendarHeaderItem>
		</BaseGrid>
	);
};
