import type { ISurvey } from "@msuite/katana";
import {
	Box,
	HStack,
	Heading,
	Text,
	VStack,
	useUIContext,
} from "@msuite/picasso";
import { type FC, useMemo } from "react";
import { BsPeopleFill } from "react-icons/bs";
import { RiTimeLine } from "react-icons/ri";
import { calculateTotalNumberOfQuestions } from "./use-survey";

/** Props Interface */
interface SurveyCardProps {
	survey: ISurvey;
	onClick?: () => void;
}

export const SurveyCard: FC<SurveyCardProps> = ({ survey, onClick }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Functions */
	const approxDuration = useMemo(() => {
		const numberOfQuestions = calculateTotalNumberOfQuestions(survey);
		const duration = numberOfQuestions * 0.25;
		return duration >= 1
			? `${duration} ${duration !== 1 ? "Minuten" : "Minute"}`
			: `${duration * 60} Sekunden`;
	}, [survey]);

	/** Render */
	return (
		<HStack
			onClick={onClick}
			p="1rem"
			backgroundColor={colors.whiteAdjusted}
			rounded="lg"
			borderWidth={1}
			cursor="pointer"
			spacing={4}
			_hover={{ opacity: 0.8 }}
			_active={{ opacity: 0.6, scale: 0.98 }}
			transition="all 0.2s"
		>
			<Box color={colors.purple}>
				<BsPeopleFill size={24} />
			</Box>
			<VStack
				align="flex-start"
				spacing={1}
			>
				<Heading size="md">{survey?.title}</Heading>
				<HStack
					fontWeight="medium"
					fontSize="sm"
				>
					<RiTimeLine />
					<Text>ca. {approxDuration}</Text>
				</HStack>
			</VStack>
		</HStack>
	);
};
