import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { db } from "@/core";
import type { ConstructionSite } from "@msuite/katana";
import {
	Box,
	Button,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverFooter,
	PopoverTrigger,
	Text,
	toast,
	useAuthContext,
	useDocument,
	useUIContext,
} from "@msuite/picasso";
import { Fragment, useState, type FC } from "react";
import { IoWarning } from "react-icons/io5";

export const MergeHint: FC = () => {
	/** State */
	const [mergeIsLoading, setMergeIsLoading] = useState<boolean>(false);

	/** Context */
	const { validation } = useAuthContext();
	const { constructionSite, onResolveMergeIssue } =
		useConstructionSiteCardContext();
	const { colors } = useUIContext();

	/** Hooks */
	const { data: mergedConstructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${constructionSite?.mergedInto}`,
		subscribe: false,
	});

	if (!mergedConstructionSite) return null;

	/** Functions */
	async function handleOnResolveMergeIssue() {
		try {
			setMergeIsLoading(true);
			if (!constructionSite?.mergedInto) return;
			await onResolveMergeIssue?.(constructionSite?.mergedInto);
			toast.success("Bauvorhaben erfolgreich ausgetauscht.");
		} catch (error) {
			toast.error(
				"Fehler beim Austragen der Bauvorhaben. Bitte wenden Sie sich an einen Administrator.",
			);
		} finally {
			setMergeIsLoading(false);
		}
	}

	/** Render */
	return (
		<Popover trigger="hover">
			<PopoverTrigger>
				<Box
					color={colors.orange}
					cursor="pointer"
				>
					<IoWarning />
				</Box>
			</PopoverTrigger>
			{validation?.isAdmin && (
				<Fragment>
					{mergedConstructionSite.mergedInto ? (
						<PopoverContent>
							<PopoverBody>
								<Text>{`Dieses Bauvorhaben wurde mit ${mergedConstructionSite.mergedInto} zusammengefasst.`}</Text>
							</PopoverBody>
							<PopoverFooter>
								<Button
									width="100%"
									onClick={handleOnResolveMergeIssue}
									isLoading={mergeIsLoading}
								>
									Bauvorhaben austauschen
								</Button>
							</PopoverFooter>
						</PopoverContent>
					) : (
						<PopoverContent>
							<PopoverBody>
								<Text>
									Dieses Bauvorhaben kann nicht automatisch verknüpft werden.
								</Text>
							</PopoverBody>
						</PopoverContent>
					)}
				</Fragment>
			)}
		</Popover>
	);
};
