import { db } from "@/core";
import type {
	ISurvey,
	ISurveyQuestion,
	ISurveyResponse,
	ISurveyResponseEntry,
	ISurveySection,
	QuestionType,
} from "@msuite/katana";
import { useAuthContext, useDocument } from "@msuite/picasso";
import { doc, setDoc } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface IActiveSurveyElement {
	type: "question" | "start_page" | "end_page";
	sectionId?: string;
	questionId?: string;
	questionType?: QuestionType;
	repeatKey?: string;
}

export function calculateTotalNumberOfQuestions(survey: ISurvey) {
	const numberOfQuestions = survey.sections.reduce((acc, section) => {
		let questions = section.questions?.length;
		if (section.repeat_section_on) {
			const repeatedQuestionMultiplicator =
				(survey.sections
					.flatMap((section) => section.questions)
					.find((q) => q.id === section.repeat_section_on)?.options?.length ||
					1) - 1;
			questions *= repeatedQuestionMultiplicator || 1;
		}
		return acc + questions;
	}, 0);
	return numberOfQuestions;
}

export function useSurvey(surveyRaw: ISurvey) {
	/** State */
	const [activeElement, setActiveElement] = useState<
		IActiveSurveyElement | undefined
	>();

	/** Hooks */
	const { user } = useAuthContext();
	const surveyPath = surveyRaw?.surveyPath || "";
	const { data: response, isLoading: responseIsLoading } =
		useDocument<ISurveyResponse>(db, {
			path: `${surveyPath}/responses/${user?.id}`,
			subscribe: true,
		});

	const dependencyQuestions = useMemo(() => {
		if (!surveyRaw) return;
		const ids = surveyRaw.sections
			.filter((section) => section.repeat_section_on)
			.map((section) => section.repeat_section_on);
		return ids;
	}, [surveyRaw]);

	const survey = useMemo(() => {
		if (!surveyRaw) return;
		if (!response) return surveyRaw;
		const newSurvey = { ...surveyRaw };
		newSurvey.sections = newSurvey.sections.flatMap((section) => {
			if (!section.repeat_section_on) return section;
			const repeatQuestion = surveyRaw.sections
				.flatMap((s) => s.questions)
				.find((q) => q.id === section.repeat_section_on);
			if (!repeatQuestion) return section;
			const repeatQuestionResponse = response.responses?.find(
				(response) => response.questionId === repeatQuestion.id,
			);
			if (!repeatQuestionResponse) return section;
			const repeatKeys = repeatQuestion.options
				?.filter(
					(option) => option.value !== repeatQuestionResponse?.answer?.[0],
				)
				.map((option) => option.value);
			if (!repeatKeys) return section;
			const newSections: ISurveySection[] = [];
			for (const repeatKey of repeatKeys) {
				newSections.push({
					...section,
					questions: section.questions.map((question) => {
						const q = { ...question };
						q.repeatKey = repeatKey;
						return q;
					}),
					repeat_key: repeatKey,
				});
			}
			return newSections;
		});
		return newSurvey;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyRaw, dependencyQuestions?.length, response?.responses]);

	const workspaceId = useMemo(() => {
		if (!surveyPath) return;
		const pathParts = surveyPath.split("/");
		return pathParts[1];
	}, [surveyPath]);

	useEffect(() => {
		if (!activeElement) setActiveElement({ type: "start_page" });
	}, [activeElement]);

	/** Functions */
	const createResponse = useCallback(async () => {
		if (!user?.id || !surveyPath) return;
		const targetPath = `${surveyPath}/responses/${user?.id}`;
		const newResponse: ISurveyResponse = {
			id: user?.id,
			surveyPath,
			createdAt: new Date().toISOString(),
			createdBy: user?.id,
			updatedAt: new Date().toISOString(),
			updatedBy: user?.id,
			responses: [],
			state: "draft",
		};
		await setDoc(doc(db, targetPath), newResponse);
	}, [user?.id, surveyPath]);

	function jumpToNextQuestion() {
		const questions = survey?.sections.flatMap((section) => section.questions);
		if (activeElement?.type === "start_page") {
			setActiveElement({
				type: "question",
				sectionId: questions?.[0].sectionId,
				questionId: questions?.[0].id,
				questionType: questions?.[0].type,
				repeatKey: questions?.[0].repeatKey,
			});
			return;
		}
		if (activeElement?.type === "question") {
			const currentQuestionIndex = questions?.findIndex(
				(q) =>
					q.id === activeElement.questionId &&
					q.repeatKey === activeElement.repeatKey &&
					q.sectionId === activeElement.sectionId,
			);
			if (currentQuestionIndex === undefined || currentQuestionIndex < 0)
				return;
			const nextQuestion = questions?.[currentQuestionIndex + 1];
			if (!nextQuestion) return setActiveElement({ type: "end_page" });
			setActiveElement({
				type: "question",
				sectionId: nextQuestion.sectionId,
				questionId: nextQuestion.id,
				questionType: nextQuestion.type,
				repeatKey: nextQuestion.repeatKey,
			});
		}
	}

	function jumpToPreviousQuestion() {
		const questions = survey?.sections.flatMap((section) => section.questions);
		if (activeElement?.type === "end_page") {
			const lastQuestion = questions?.[questions.length - 1];
			if (!lastQuestion) return setActiveElement({ type: "start_page" });
			setActiveElement({
				type: "question",
				sectionId: lastQuestion.sectionId,
				questionId: lastQuestion.id,
				questionType: lastQuestion.type,
				repeatKey: lastQuestion.repeatKey,
			});
		} else if (activeElement?.type === "question") {
			const currentQuestionIndex = questions?.findIndex(
				(q) =>
					q.id === activeElement.questionId &&
					q.repeatKey === activeElement.repeatKey &&
					q.sectionId === activeElement.sectionId,
			);
			if (currentQuestionIndex === undefined || currentQuestionIndex < 0)
				return;
			const previousQuestion = questions?.[currentQuestionIndex - 1];
			if (!previousQuestion) return setActiveElement({ type: "start_page" });
			setActiveElement({
				type: "question",
				sectionId: previousQuestion.sectionId,
				questionId: previousQuestion.id,
				questionType: previousQuestion.type,
				repeatKey: previousQuestion.repeatKey,
			});
		}
	}

	function getActiveQuestion() {
		if (!survey) return;
		if (!activeElement) return;
		if (activeElement.type === "question") {
			const section = survey.sections.find(
				(section) => section.id === activeElement.sectionId,
			);
			if (!section) return;
			const question = section.questions.find(
				(question) => question.id === activeElement.questionId,
			);
			if (!question) return;
			return question;
		}
	}

	function getActiveSection() {
		if (!survey) return;
		if (!activeElement) return;
		if (activeElement.type === "question") {
			const section = survey.sections.find(
				(section) => section.id === activeElement.sectionId,
			);
			if (!section) return;
			return section;
		}
	}

	function getActiveQuestionIndex() {
		if (!survey) return;
		if (!activeElement) return;
		if (activeElement.type === "question") {
			const section = survey.sections.find(
				(section) => section.id === activeElement.sectionId,
			);
			if (!section) return;
			const questionIndex = section.questions.findIndex(
				(question) => question.id === activeElement.questionId,
			);
			if (questionIndex === -1) return;
			return questionIndex;
		}
	}

	function getActiveSectionIndex() {
		if (!survey) return;
		if (!activeElement) return;
		if (activeElement.type === "question") {
			const sectionIndex = survey.sections.findIndex(
				(section) => section.id === activeElement.sectionId,
			);
			if (sectionIndex === -1) return;
			return sectionIndex;
		}
	}

	function getCurrentResponse(
		question: ISurveyQuestion | undefined,
	): ISurveyResponseEntry | undefined {
		if (!survey) return;
		if (!question) return;
		const res = response?.responses?.find(
			(r) =>
				r.questionId === question.id &&
				r.repeatKey === question.repeatKey &&
				r.sectionId === question.sectionId,
		);
		return res;
	}

	const progress = useMemo(() => {
		if (activeElement?.type === "start_page") return 0;
		if (activeElement?.type === "end_page") return 100;
		const questions = survey?.sections.flatMap((section) => section.questions);
		if (activeElement?.type === "question") {
			const currentQuestionIndex = questions?.findIndex(
				(q) =>
					q.id === activeElement.questionId &&
					q.repeatKey === activeElement.repeatKey &&
					q.sectionId === activeElement.sectionId,
			);
			if (currentQuestionIndex === undefined || currentQuestionIndex < 0)
				return 0;
			return Math.round(
				(currentQuestionIndex / (questions?.length || 0)) * 100,
			);
		}
	}, [activeElement, survey?.sections]);

	/** Effects */
	useEffect(() => {
		if (response === undefined && surveyRaw !== undefined && !responseIsLoading)
			createResponse();
	}, [response, responseIsLoading, surveyRaw, createResponse]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (activeElement !== undefined) return;
		if (responseIsLoading) return;
		if (response === undefined) return;
		if ((response.responses?.length || 0) === 0)
			setActiveElement({ type: "start_page" });
		else {
			const questions = [
				...(survey?.sections.flatMap((section) => section.questions) || []),
			].reverse();
			const lastResponse = questions.find((question) => {
				const res = response.responses?.find(
					(r) =>
						r.questionId === question.id &&
						r.repeatKey === question.repeatKey &&
						r.sectionId === question.sectionId,
				);
				return res !== undefined;
			});
			if (lastResponse === undefined)
				return setActiveElement({ type: "start_page" });
			setActiveElement({
				type: "question",
				sectionId: lastResponse.sectionId,
				questionId: lastResponse.id,
				questionType: lastResponse.type,
				repeatKey: lastResponse.repeatKey,
			});
		}
	});

	/** Render */
	return {
		survey,
		response,
		surveyPath,
		activeElement,
		setActiveElement,
		jumpToNextQuestion,
		jumpToPreviousQuestion,
		getActiveQuestion,
		getActiveSection,
		getActiveQuestionIndex,
		getActiveSectionIndex,
		getCurrentResponse,
		progress,
		workspaceId,
	};
}
