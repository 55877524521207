import {
	type CampaignAnswer,
	getContactTypeString,
	moment,
} from "@msuite/katana";
import { createColumnHelper } from "@tanstack/react-table";
import { RowActions } from "./row-actions";

const columnHelper = createColumnHelper<CampaignAnswer>();

export const columns = [
	columnHelper.accessor("title", {
		header: "Anrede",
		meta: {
			width: "7.5%",
		},
		cell: (cell) => getContactTypeString(cell.getValue()) || "--",
	}),
	columnHelper.accessor("firstName", {
		header: "Vorname",
		meta: {
			width: "10%",
		},
		cell: (cell) => cell.getValue() || "--",
	}),
	columnHelper.accessor("lastName", {
		header: "Nachname",
		meta: {
			width: "10%",
		},
		cell: (cell) => cell.getValue() || "--",
	}),
	columnHelper.accessor("email", {
		header: "Email",
		cell: (cell) => cell.getValue() || "--",
	}),
	columnHelper.accessor("createdAt", {
		header: "Versendet am",
		cell: (cell) =>
			cell.getValue()
				? moment(cell.getValue()).format("dd, L - HH:mm:ss [Uhr]")
				: "Unbeantwortet",
	}),
	columnHelper.accessor("answeredAt", {
		header: "Beantwortet am",
		cell: (cell) =>
			cell.getValue()
				? moment(cell.getValue()).format("dd, L - HH:mm:ss [Uhr]")
				: "--",
	}),
	columnHelper.accessor("answer", {
		header: "",
		meta: {
			width: "10%",
			isNumeric: true,
		},
		cell: (cell) =>
			cell.getValue() ? (cell.getValue() === "yes" ? "Ja" : "Nein") : "--",
	}),
	columnHelper.display({
		id: "actions",
		header: "",
		meta: {
			width: "10%",
			isNumeric: true,
		},
		cell: (cell) => <RowActions campaignAnswer={cell.row.original} />,
	}),
];
