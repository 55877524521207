import type { Moment } from "@msuite/katana";
import {
	useColorModeValue,
	useEffectOnce,
	useToken,
	useUIContext,
} from "@msuite/picasso";
import { motion } from "framer-motion";
import { type FC, useState } from "react";
import { BASE_ITEM_BORDER_RADIUS } from "./base-item";
import { useDatePickerContext } from "./context";
import type { BaseItemMode } from "./handlers";

/** Props Interface */
interface FocusElementProps {
	mode: "hover" | "focus";
	parentMode: BaseItemMode;
	parentId: string;
	parentDateFormat: string;
	parentDate: Moment;
}

export const FocusElement: FC<FocusElementProps> = ({
	mode,
	parentId,
	parentDate,
	parentMode,
}) => {
	/** Context */
	const { renderId } = useDatePickerContext();
	const { colors } = useUIContext();

	/** State */
	const [measures, setMeasures] = useState<{
		width: number;
		height: number;
	} | null>(null);

	/** Constants */
	const backgroundColor = useToken("colors", colors.gray);
	const opacity = useColorModeValue(0.1, 0.25);

	/** Hooks */
	useEffectOnce(() => {
		if (parentMode !== "week") return;
		const parentElement = document.getElementById(parentId);
		if (!parentElement) return;
		const domRect = parentElement.getBoundingClientRect();
		const left = domRect.left;
		let width = domRect.width;
		const height = domRect.height;
		const weekEnd = parentDate.clone().endOf("week").format("YYYY-MM-DD");
		const weekEndElement = document.getElementById(`${renderId}-${weekEnd}`);
		if (weekEndElement) {
			const weekEndRect = weekEndElement.getBoundingClientRect();
			const right = weekEndRect.right;
			width = right - left;
		}
		setMeasures({ width, height });
	});

	/** Render */
	return (
		<motion.div
			layoutId={mode === "focus" ? `${renderId}-focus` : undefined}
			transition={{
				type: "tween",
				ease: "anticipate",
				duration: 0.2,
			}}
			style={{
				mixBlendMode: "multiply",
				opacity,
				borderWidth: "1px",
				borderStyle: "solid",
				borderColor: backgroundColor,
				width: measures?.width || "100%",
				height: measures?.height || "100%",
				position: "absolute",
				top: 0,
				left: 0,
				backgroundColor,
				pointerEvents: "none",
				zIndex: 1,
				borderRadius: BASE_ITEM_BORDER_RADIUS,
			}}
		/>
	);
};
