import { ax } from "@/core";
import { TableSeparatorWrapper } from "@/sites/personal/employees/table-separator-wrapper";
import type { IGetEmployeesApiResponse } from "@msuite/katana";
import {
	Button,
	Input,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	type RowSelectionState,
	StandardDataTable,
	VStack,
	useApi,
	useTableOptions,
} from "@msuite/picasso";
import { type FC, useEffect, useState } from "react";
import { columns } from "./table-config";

/** Props Interface */
interface SelectEmployeesModalProps {
	isOpen: boolean;
	onClose: () => void;
	defaultSelectedEmployeeIds: string[];
	onChange: (employeeIds: string[]) => void;
}

export const SelectEmployeesModal: FC<SelectEmployeesModalProps> = ({
	isOpen,
	onClose,
	defaultSelectedEmployeeIds,
	onChange,
}) => {
	/** State */
	const [isInitiallyHydrated, setIsInitiallyHydrated] =
		useState<boolean>(false);

	/** Hooks */
	const tableOptions = useTableOptions();
	const { data, isFetching } = useApi<IGetEmployeesApiResponse>(ax, {
		url: "/v2/employees?state=active",
		dependencies: ["employees", "active"],
	});

	function mapRowSelectionToEmployeeIds(
		rowSelection: RowSelectionState,
	): string[] {
		const selectedIndices = Object.keys(rowSelection);
		const selectedIds = selectedIndices.map(
			(index) => data?.employees[Number(index)].id ?? "",
		);
		onChange(selectedIds);
		return selectedIds;
	}

	function mapIdsToRowSelection(employeeIds: string[]): RowSelectionState {
		if (!data) return {};
		const rowSelection: RowSelectionState = {};
		for (let i = 0; i < data.employees.length; i++) {
			const employee = data.employees[i];
			if (employeeIds.includes(employee.id ?? "")) {
				rowSelection[i] = true;
			}
		}
		return rowSelection;
	}

	/** Effects */
	useEffect(() => {
		if (!isInitiallyHydrated) return;
		mapRowSelectionToEmployeeIds(tableOptions.rowSelection);
	}, [tableOptions.rowSelection, isInitiallyHydrated]);

	useEffect(() => {
		if (isInitiallyHydrated) return;
		if (data && defaultSelectedEmployeeIds) {
			const rowSelection = mapIdsToRowSelection(defaultSelectedEmployeeIds);
			tableOptions.setRowSelection(rowSelection);
			setIsInitiallyHydrated(true);
		}
	}, [data, defaultSelectedEmployeeIds, isInitiallyHydrated]);

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent width="90rem">
				<ModalCloseButton />
				<ModalHeader>Mitarbeiter auswählen</ModalHeader>
				<ModalBody>
					<VStack>
						<Input
							value={tableOptions.globalFilter}
							onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
							isSearch
						/>
						<StandardDataTable
							{...tableOptions}
							hasRowSelection
							enableMultiRowSelection
							allowSelectAll
							isLoading={isFetching}
							data={data?.employees ?? []}
							columns={columns}
							RowSeparator={TableSeparatorWrapper}
							rowSeparatorKey="additionalData.employeeGroup.id"
							footerLabel="Mitarbeiter"
							hidePagination
						/>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button onClick={onClose}>Fertig</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
