import { ConstructionSiteCardContext } from "@/context/construction-site-card";
import { usePlanContext } from "@/context/plan";
import { db } from "@/core";
import { EditConstructionSiteModal } from "@/modals/construction-sites/edit-construction-site-modal";
import type { ConstructionSite, Ticket } from "@msuite/katana";
import {
	Box,
	ContextMenu,
	VStack,
	useAuthContext,
	useDebouncedValue,
	useDisclosure,
	useDocument,
	useTargetValidation,
	useUIContext,
} from "@msuite/picasso";
import { type FC, useMemo, useState } from "react";
import {
	TicketCardDefaultModules,
	type TicketCardModule,
} from "../ticket-card";
import { AssignLine } from "./assign-line";
import { MainInformation } from "./main-information";
import { ConstructionSiteCardSkeleton } from "./skeleton";
import { Tickets } from "./tickets";
import { useContextMenu } from "./use-context-menu";
import { useOpacityHandler } from "./use-opacity-handler";

/** Styles */
const style = {
	borderRadius: "xl",
	borderWidth: 1,
	p: 3,
	pt: 2,
};

/** Props Interface */
export interface ConstructionSiteCardProps {
	htmlId?: string;
	constructionSiteId: string | undefined;
	subscribe?: boolean;
	shouldSubscribeOutsideViewport?: boolean;
	ticketFilterFn?: (ticket: Ticket) => boolean;
	modules?: ConstructionSiteCardModule[];
	ticketCardModules?: TicketCardModule[];
	onClick?: () => void;
	bottomMargin?: number;
	generateTicketDraggableId?: (ticket: Ticket, index?: number) => string;
	disableTicketDrag?: boolean;
	ticketsDroppableType?: string;
	onRemoveConstructionSite?: () => Promise<void>;
	onRemoveTicket?: (ticketId: Ticket, ticketIndex: number) => Promise<void>;
	onTicketDrop?: (
		ticketId: string | string[],
		constructionSiteId: string,
	) => Promise<void>;
	shouldFetchMetaInformation?: boolean;
	shouldHandleOpacity?: boolean;
	ticketIndexOverride?: number;
	isInView?: boolean;
	contextMenuType: ContextMenuType;
	withMultiSelect?: boolean;
	ignoreInactiveTickets?: boolean;
	alwaysShowWorkspaceBadge?: boolean;
	onResolveMergeIssue: ((constructionSiteId: string) => void) | undefined;
}

/** Types */
export type ConstructionSiteCardModule =
	| "main-information"
	| "assign-line"
	| "add-ticket"
	| "tickets";
export type ContextMenuType = "daily" | "weekly" | "none";

export const ConstructionSiteCardContent: FC<ConstructionSiteCardProps> = ({
	htmlId,
	constructionSiteId,
	subscribe,
	shouldSubscribeOutsideViewport,
	ticketFilterFn = () => true,
	modules = ["main-information", "tickets"],
	ticketCardModules = TicketCardDefaultModules,
	onClick,
	bottomMargin = 2,
	generateTicketDraggableId,
	disableTicketDrag = false,
	ticketsDroppableType,
	onRemoveConstructionSite,
	onRemoveTicket,
	shouldFetchMetaInformation = false,
	shouldHandleOpacity = false,
	ticketIndexOverride,
	onTicketDrop,
	isInView,
	contextMenuType,
	withMultiSelect,
	ignoreInactiveTickets = false,
	alwaysShowWorkspaceBadge = false,
	onResolveMergeIssue,
}) => {
	/** State */
	const [showAllActiveTickets, setShowAllActiveTickets] = useState(false);

	/** Hooks */
	const debouncedIsInView = useDebouncedValue(isInView, 500);
	const { userId, validation } = useAuthContext();
	const { colors } = useUIContext();
	const { activeDailyColons } = usePlanContext();
	const { data: _constructionSite, isError } = useDocument<ConstructionSite>(
		db,
		{
			path: `baustellen/${constructionSiteId}`,
			subscribe:
				subscribe && (debouncedIsInView || shouldSubscribeOutsideViewport),
		},
	);

	const { data: archivedConstructionSite } = useDocument<ConstructionSite>(db, {
		path: `archive/baustellen/deleted/${constructionSiteId}`,
		subscribe: false,
		queryClientOptions: { enabled: isError },
	});

	const constructionSite = _constructionSite || archivedConstructionSite;
	const isArchived = !!archivedConstructionSite;
	const isLoading = !constructionSite;
	const editConstructionSiteModal = useDisclosure();
	const { contextMenuOptions, contextMenuRef } = useContextMenu(
		contextMenuType,
		constructionSite,
		constructionSiteId,
		editConstructionSiteModal,
		showAllActiveTickets,
		setShowAllActiveTickets,
	);
	const userIsOwner = useTargetValidation(
		(constructionSite?.bauleiter ?? []).concat(
			constructionSite?.currentDeputies ?? [],
		),
		userId,
		validation?.isAdmin,
	);

	/** Memoized */
	const hasMainInformation = useMemo(
		() => modules.includes("main-information"),
		[modules],
	);
	const hasTickets = useMemo(() => modules.includes("tickets"), [modules]);
	const hasAssignLine = useMemo(
		() => modules.includes("assign-line"),
		[modules],
	);
	const hasCurrentAssignment = useMemo(() => {
		if (!constructionSiteId || !shouldFetchMetaInformation) return false;
		return activeDailyColons
			.flatMap((colon) => colon.baustellen)
			.some((c) => c?.id === constructionSiteId);
	}, [activeDailyColons, constructionSiteId, shouldFetchMetaInformation]);
	const { opacity, handleAddToEditingMap, handleRemoveFromEditingMap } =
		useOpacityHandler(
			constructionSiteId,
			shouldHandleOpacity,
			hasCurrentAssignment,
		);

	/** Functions */
	function handleOnClick() {
		handleAddToEditingMap();
	}

	function handleOnDoubleClick() {
		handleRemoveFromEditingMap();
	}

	/** Render */
	return (
		<>
			<ConstructionSiteCardContext.Provider
				value={{
					isLoading,
					constructionSiteId,
					subscribe,
					shouldSubscribeOutsideViewport,
					ticketFilterFn,
					modules,
					ticketCardModules,
					onClick,
					bottomMargin,
					constructionSite,
					generateTicketDraggableId,
					disableTicketDrag,
					ticketsDroppableType,
					onRemoveConstructionSite,
					onRemoveTicket,
					shouldFetchMetaInformation,
					hasCurrentAssignment,
					ticketIndexOverride,
					isInView: debouncedIsInView,
					onTicketDrop,
					contextMenuType,
					editConstructionSiteModal,
					userIsOwner,
					withMultiSelect,
					ignoreInactiveTickets,
					showAllActiveTickets,
					setShowAllActiveTickets,
					alwaysShowWorkspaceBadge,
					onResolveMergeIssue,
					isArchived,
				}}
			>
				<Box
					style={{ opacity }}
					_hover={{ opacity: "1 !important" }}
					transition="opacity 0.1s linear"
					onClickCapture={handleOnClick}
					onDoubleClick={handleOnDoubleClick}
					cursor={onClick ? "pointer" : undefined}
					id={htmlId}
				>
					<ContextMenu
						options={contextMenuOptions}
						shouldRender={isInView}
					>
						<VStack
							onClick={onClick}
							sx={{
								...style,
								borderColor: isArchived ? colors.orange : undefined,
								borderStyle: isArchived ? "dashed" : undefined,
								backgroundColor: colors.whiteAdjusted,
								paddingBottom: hasTickets ? 1 : 3,
							}}
							spacing={1}
						>
							{isLoading ? (
								<ConstructionSiteCardSkeleton />
							) : (
								<>
									{hasAssignLine && <AssignLine />}
									{hasMainInformation && <MainInformation />}
									{hasTickets && <Tickets ref={contextMenuRef} />}
								</>
							)}
						</VStack>
					</ContextMenu>
				</Box>
			</ConstructionSiteCardContext.Provider>
			{constructionSite && editConstructionSiteModal.isOpen && (
				<EditConstructionSiteModal
					isOpen={editConstructionSiteModal.isOpen}
					onClose={editConstructionSiteModal.onClose}
					constructionSite={constructionSite}
					shouldAddToWeek
				/>
			)}
		</>
	);
};
