import { useToken } from "@msuite/picasso";
import { motion } from "framer-motion";
import type { FC } from "react";

/** Props Interface */
interface UploadAnimationBarProps {
	isInitiallyLoaded: boolean;
	setIsInitiallyLoaded: (value: boolean) => void;
}

export const UploadAnimationBar: FC<UploadAnimationBarProps> = ({
	isInitiallyLoaded,
	setIsInitiallyLoaded,
}) => {
	/** Tokens */
	const green = useToken("colors", "green.500");

	/** Render */
	return (
		<motion.div
			style={{
				height: 2,
				position: "absolute",
				zIndex: 4,
				backgroundColor: green,
				bottom: 0,
				left: 0,
			}}
			initial={{ width: "0%" }}
			animate={{
				width: "100%",
				opacity: isInitiallyLoaded ? 0 : 1,
			}}
			onAnimationComplete={() => setIsInitiallyLoaded(true)}
			transition={{
				type: "keyframes",
				duration: 1,
			}}
		/>
	);
};
