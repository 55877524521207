import type { Moment } from "@msuite/katana";
import type { useHolidays } from "@msuite/picasso";
import { createContext, useContext } from "react";
import type { BaseItemMode } from "./handlers";

/** Props Interface */
interface IDatePickerContextProps {
	currentMonth: Moment;
	setCurrentMonth: (moment: Moment) => void;
	currentValue: string | null;
	setCurrentValue: (value: string | null) => void;
	renderId: string;
	handleOnClose: () => void;
	handleOnSelect: (date: Moment, mode: BaseItemMode) => void;
	holidays: ReturnType<typeof useHolidays>["holidaysAsArray"];
	minValue: string;
}

/** Context */
export const DatePickerContext = createContext<IDatePickerContextProps>(
	{} as IDatePickerContextProps,
);

/** Hooks */
export const useDatePickerContext = () => {
	return useContext(DatePickerContext);
};
