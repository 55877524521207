import { FileCard } from "@/components/card";
import { useFileGalleryModalContext } from "@/context/file-gallery-modal";
import { generateId } from "@msuite/katana";
import {
	Center,
	Label,
	Text,
	VStack,
	toast,
	useDropzone,
	useUIContext,
} from "@msuite/picasso";
import type { FC } from "react";
import { FileBlankSlate } from "./file-blank-slate";

function createUniqueFileName(file: File) {
	const randomId = generateId();
	return changeFileName(file, `${randomId}-${file.name}`);
}

export function changeFileName(file: File, newName: string) {
	const newFile = new File([file], `${newName}`, { type: file.type });
	return newFile;
}

export const Upload: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const { files, setFiles } = useFileGalleryModalContext();

	/** Hooks */
	const methods = useDropzone({ onDrop });

	/** Functions */
	function handleOnRemove(index: number) {
		const currentFiles = [...files];
		setFiles(currentFiles.filter((_, i) => i !== index));
	}

	function handleOnRename(index: number, name: string) {
		try {
			const thisFile = files[index];
			const newFile = changeFileName(thisFile, name);
			const currentFiles = [...files];
			currentFiles[index] = newFile;
			setFiles(currentFiles);
		} catch (error) {
			toast.error("Dateiname konnte nicht geändert werden.");
		}
	}

	async function onDrop(_acceptedFiles: File[]) {
		try {
			const acceptedFiles = _acceptedFiles.map(createUniqueFileName);
			const currentFiles = [...files, ...acceptedFiles];
			setFiles(currentFiles);
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<VStack spacing={2}>
			<Center
				width="100%"
				height="10rem"
				rounded="md"
				borderWidth={1}
				borderStyle="dashed"
				borderColor={methods.isDragAccept ? colors.brand : undefined}
				cursor="pointer"
				_hover={{
					borderColor: colors.brand,
				}}
				{...methods.getRootProps()}
				backgroundColor={colors.whiteAdjusted}
			>
				<input
					style={{ visibility: "hidden" }}
					{...methods.getInputProps()}
				/>
				<Text>
					Ziehen Sie Dateien hierher oder klicken Sie auf das Feld, um Dateien
					hochzuladen.
				</Text>
			</Center>
			{(files?.length ?? 0) > 0 && (
				<>
					<Label>Dateien ({files.length})</Label>
					{!files?.length && <FileBlankSlate />}
					{files.map((file, index) => (
						<FileCard
							key={`${file.name}${index}`}
							file={file}
							index={index}
							isContentEditable
							onRemove={() => handleOnRemove(index)}
							onRename={handleOnRename}
						/>
					))}
				</>
			)}
		</VStack>
	);
};
