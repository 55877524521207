import { Grid, Skeleton } from "@msuite/picasso";
import type { FC } from "react";

function getRandomWidth(minValue: number) {
	const maxValue = 100;
	const width = Math.floor(
		Math.random() * (maxValue - minValue + 1) + minValue,
	);
	return `${width}%`;
}

export const ConstructionSiteCardSkeleton: FC = () => {
	return (
		<Grid
			templateColumns="2fr 1fr"
			columnGap={4}
			rowGap={3}
			pt={2}
		>
			{Array.from({ length: 6 }).map((_, index) => (
				<Skeleton
					// biome-ignore lint: index required
					key={index}
					height="1rem"
					width={getRandomWidth(index % 2 === 0 ? 70 : 25)}
					placeSelf={index % 2 === 0 ? "flex-start" : "flex-end"}
				/>
			))}
		</Grid>
	);
};
