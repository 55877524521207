import { ax, db } from "@/core";
import { type ISurvey, type User, moment } from "@msuite/katana";
import type { DashboardAlert } from "@msuite/picasso";
import {
	type Tiles,
	useAuthContext,
	useDisclosure,
	useQuery,
} from "@msuite/picasso";
import { doc, getDoc } from "firebase/firestore";

export const DASHBOARD_INTERNAL_SUBCONTRACTOR_LAYOUT_ID = "timesheets-modal";
export const DASHBOARD_VACATIONS_LAYOUT_ID = "vacations-modal";
export const DASHBOARD_CALL_NOTES_LAYOUT_ID = "call-notes-modal";
export const DASHBOARD_RENT_LIST_LAYOUT_ID = "rent-list-modal";
export const DASHBOARD_YEARLY_STATEMENT_LAYOUT_ID = "yearly-statement-modal";

export function useDashboard() {
	/** Context */
	const { validation, userId } = useAuthContext();

	/** Hooks */
	const editTimesheetsModal = useDisclosure();
	const editVacationsModal = useDisclosure();
	const editCallNotesModal = useDisclosure();
	const editRentListModal = useDisclosure();
	const editSurveysModal = useDisclosure();
	const editYearlyStatementModal = useDisclosure();

	/** Functions */
	function buildTiles() {
		if (validation?.isBauleiter) return buildManagerTiles();
		if (validation?.isVerwaltung) return buildAdminstrationTiles();
		if (validation?.isFrontdesk) return buildFrontdeskTiles();
		if (validation?.isLogistics) return buildLogisticsTiles();
		if (validation?.isMitarbeiter) return buildEmployeeTiles();
		return [];
	}

	function buildManagerTiles() {
		const tiles: Tiles = [
			{ type: "weekly-overview", renderOnWorkspaceChange: true },
			{
				type: "internal-subcontractor",
				layoutId: DASHBOARD_INTERNAL_SUBCONTRACTOR_LAYOUT_ID,
				onClick: editTimesheetsModal.onOpen,
			},
			{
				type: "site-measurements",
				onClick: () => window.open("/my-space/site-measurements", "_blank"),
			},
			{ type: "daily-overview", renderOnWorkspaceChange: true },
			{
				type: "call-notes",
				layoutId: DASHBOARD_CALL_NOTES_LAYOUT_ID,
				onClick: editCallNotesModal.onOpen,
			},
			{
				type: "vacations",
				layoutId: DASHBOARD_VACATIONS_LAYOUT_ID,
				onClick: editVacationsModal.onOpen,
			},
		];
		return tiles;
	}

	function buildEmployeeTiles() {
		const tiles: Tiles = [
			{
				type: "vacations",
				layoutId: DASHBOARD_VACATIONS_LAYOUT_ID,
				onClick: editVacationsModal.onOpen,
			},
			{
				type: "call-notes",
				layoutId: DASHBOARD_CALL_NOTES_LAYOUT_ID,
				onClick: editCallNotesModal.onOpen,
			},
		];
		return tiles;
	}

	function buildLogisticsTiles() {
		const tiles: Tiles = [
			{ type: "weekly-overview", renderOnWorkspaceChange: true },
			{
				type: "vacations",
				layoutId: DASHBOARD_VACATIONS_LAYOUT_ID,
				onClick: editVacationsModal.onOpen,
			},
			{ type: "daily-overview", renderOnWorkspaceChange: true },
			{
				type: "call-notes",
				layoutId: DASHBOARD_CALL_NOTES_LAYOUT_ID,
				onClick: editCallNotesModal.onOpen,
			},
		];
		return tiles;
	}

	function buildAdminstrationTiles() {
		const tiles: Tiles = [
			{ type: "weekly-overview", renderOnWorkspaceChange: true },
			{
				type: "vacations",
				layoutId: DASHBOARD_VACATIONS_LAYOUT_ID,
				onClick: editVacationsModal.onOpen,
			},
			{ type: "daily-overview", renderOnWorkspaceChange: true },
			{
				type: "call-notes",
				layoutId: DASHBOARD_CALL_NOTES_LAYOUT_ID,
				onClick: editCallNotesModal.onOpen,
			},
		];
		return tiles;
	}

	function buildFrontdeskTiles() {
		return buildAdminstrationTiles();
	}

	const tiles = buildTiles();

	async function fetchSurveys() {
		const { data } = await ax.get<{
			surveys: (ISurvey & { survey_path: string })[];
		}>(`/surveys/user/${userId}`);
		return data?.surveys ?? [];
	}

	async function fetchAlerts() {
		try {
			const _alerts: DashboardAlert[] = [];
			const userRef = doc(db, `user/${userId}`);
			const user = (await getDoc(userRef)).data() as User;
			if (user.deadline) {
				_alerts.push({
					type: "warning",
					alertType: "rent-list",
					message: `Beantwortung der Mietliste ist erforderlich. Sie müssen diese Liste bis zum ${moment(
						user.deadline,
					).format(
						"L",
					)} beantworten, sonst wird der Funktionsumfang der MenkeSuite vorrübergehend eingeschränkt.`,
				});
			}
			const surveys = await fetchSurveys();
			if (surveys.length > 0) {
				_alerts.push({
					type: "info",
					alertType: "misc",
					message: `Es gibt ${surveys.length} offene Umfragen.`,
					onClick: editSurveysModal.onOpen,
				});
			}
			if (
				moment().isBetween(
					moment(`${moment().year()}-10-28`),
					moment().endOf("year"),
				) &&
				validation?.isBauleiter
			) {
				_alerts.push({
					type: "info",
					alertType: "misc",
					message: `Hier klicken um die Angaben zur Jahresendabrechnung ${moment().year()} zu bearbeiten.`,
					onClick: editYearlyStatementModal.onOpen,
					layoutId: DASHBOARD_YEARLY_STATEMENT_LAYOUT_ID,
				});
			}
			return _alerts;
		} catch (error) {
			return [] as DashboardAlert[];
		}
	}

	const { data: alerts, refetch: handleOnRefetchAlerts } = useQuery(
		["dashboard", "alerts", userId],
		fetchAlerts,
		{
			enabled: !!userId,
			refetchOnMount: true,
		},
	);

	/** Render */
	return {
		tiles,
		alerts: alerts?.map((alert) =>
			alert.alertType === "rent-list"
				? { ...alert, onClick: editRentListModal.onOpen }
				: alert,
		),
		editSurveysModal,
		editTimesheetsModal,
		editVacationsModal,
		editCallNotesModal,
		editRentListModal,
		editYearlyStatementModal,
		handleOnRefetchAlerts,
	};
}
