import { VStack, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { Canvas } from "./canvas";

export const SurveyDisplay: FC = () => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<VStack
			alignSelf="stretch"
			justifySelf="stretch"
			bg={colors.whiteAdjusted}
			position="relative"
			width="100%"
			height="100%"
			minH="0"
			overflow="hidden"
		>
			<Canvas />
		</VStack>
	);
};
