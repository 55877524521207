import { Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface CalendarHeaderItemProps {
	children: React.ReactNode;
}

export const CalendarHeaderItem: FC<CalendarHeaderItemProps> = ({
	children,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Text
			color={colors.gray}
			style={{
				height: "1.5rem",
			}}
		>
			{children}
		</Text>
	);
};
