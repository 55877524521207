import type { ProjectRequest } from "@msuite/katana";
import { HStack, Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface ProjectRequestHeaderProps {
	projectRequest: ProjectRequest | undefined;
}

export const ProjectRequestHeader: FC<ProjectRequestHeaderProps> = ({
	projectRequest,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<HStack
			rounded="xl"
			backgroundColor={colors.whiteAdjusted}
			spacing={0}
			overflow="hidden"
			flexShrink={0}
			borderWidth={1}
			py={4}
			px={5}
		>
			<Text>{projectRequest?.city}</Text>
		</HStack>
	);
};
