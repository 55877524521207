import { VStack } from "@msuite/picasso";
import type { FC } from "react";
import { OverviewAnswerToSelect } from "./overview-answer-to-select";
import { OverviewCallbackDate } from "./overview-callback-date";

export const OverviewCallbackInformation: FC = () => {
	return (
		<VStack>
			<OverviewAnswerToSelect />
			<OverviewCallbackDate />
		</VStack>
	);
};
