import { Box } from "@msuite/picasso";
import type { FC } from "react";
import { IoChevronDown } from "react-icons/io5";

/** Props Interface */
interface CollapserProps {
	isCollapsed: boolean;
	onToggle: () => void;
}

export const Collapser: FC<CollapserProps> = ({ isCollapsed, onToggle }) => {
	/** Render */
	return (
		<Box
			fontSize="sm"
			onClick={onToggle}
			cursor="pointer"
			_hover={{ opacity: 0.8 }}
			transition="0.23s"
			sx={{ transform: isCollapsed ? "rotate(-90deg)" : "rotate(0deg)" }}
		>
			<IoChevronDown />
		</Box>
	);
};
