import type { Entry } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IEntryCommentsSectionContext {
	constructionSiteId: string | undefined;
	entry: Entry;
	isPopover: boolean;
}

/** Context */
export const EntryCommentsSectionContext =
	createContext<IEntryCommentsSectionContext>(
		{} as IEntryCommentsSectionContext,
	);

/** Hooks */
export const useEntryCommentsSectionContext = () => {
	return useContext(EntryCommentsSectionContext);
};
