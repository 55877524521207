import { MentionInput } from "@/components/mention-input";
import { Dropzone } from "@/components/upload";
import { ax } from "@/core";
import type { ISiteManagersApiResponse } from "@msuite/katana";
import {
	Button,
	FormControl,
	FormLabel,
	HStack,
	VStack,
	useApi,
} from "@msuite/picasso";
import { type FC, useState } from "react";

export const AddNote: FC = () => {
	/** State */
	const [addFilesVisible, setAddFilesVisible] = useState<boolean>(false);
	const [value, setValue] = useState<string>("");

	/** Hooks */
	const { data } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees/site-managers",
		dependencies: ["site-managers"],
	});

	/** Functions */
	function handleOnToggleAddFiles() {
		setAddFilesVisible(!addFilesVisible);
	}

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Notizen</FormLabel>
				<MentionInput
					value={value}
					onChange={setValue}
					items={
						data?.employees.map((employee) => ({
							id: employee.id,
							display: `${employee.vorname} ${employee.nachname}`,
						})) ?? []
					}
				/>
			</FormControl>
			{addFilesVisible && (
				<Dropzone
					onFilesChange={() => {}}
					onRemoveFile={() => {}}
				/>
			)}
			<HStack justify="flex-end">
				<Button
					onClick={handleOnToggleAddFiles}
					variant="ghost"
				>
					Datei hinzufügen
				</Button>
				<Button>Speichern</Button>
			</HStack>
		</VStack>
	);
};
