import { Grid } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface BaseGridProps {
	children: React.ReactNode;
}

export const BaseGrid: FC<BaseGridProps> = ({ children }) => {
	return (
		<Grid
			templateColumns="1.5fr repeat(7, 1fr)"
			autoRows="max-content"
			placeItems="center"
			fontSize="sm"
		>
			{children}
		</Grid>
	);
};
