import { FormControl, FormLabel, Grid, Text } from "@msuite/picasso";
import type { FC } from "react";
import { CallOtherReasonLabels } from "../form/schema";
import { useCallNoteOverviewContext } from "./context";

export const OverviewReasonOther: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Fallback */
	if (callNote.reason.type !== "other") return null;

	/** Render */
	return (
		<Grid
			columnGap={6}
			rowGap={6}
			templateColumns="1fr"
		>
			<FormControl>
				<FormLabel>Art</FormLabel>
				<Text>{CallOtherReasonLabels[callNote.reason.otherReason]}</Text>
			</FormControl>
		</Grid>
	);
};
