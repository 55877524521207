import { BlankSlateContainer, ContentContainer } from "@msuite/picasso";
import type { FC } from "react";
import { useProcessTimesheetsModalContext } from "./context";
import { Control } from "./control";
import { List } from "./list";

export const Content: FC = () => {
	/** Context */
	const { selectedPublishEntry } = useProcessTimesheetsModalContext();

	/** Render */
	return (
		<ContentContainer>
			<List />
			{selectedPublishEntry ? (
				<Control key={selectedPublishEntry.entry.id} />
			) : (
				<BlankSlateContainer
					heading="Stundenzettel auswählen"
					subtext="Wählen Sie einen Stundenzettel aus, um ihn zu bearbeiten."
				/>
			)}
		</ContentContainer>
	);
};
