import { ax } from "@/core";
import {
	type Absence,
	absenceCategoryToReadable,
	absenceTypeToReadable,
	moment,
} from "@msuite/katana";
import {
	AbsenceStatusBadge,
	BlankSlateContainer,
	HStack,
	Heading,
	ListContainer,
	Text,
	VStack,
	useApi,
	useDisclosure,
	useUIContext,
	useVacationsTileStore,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { AddAbsenceModal } from "../add-absence-modal";

/** Props Interface */
interface ListProps {
	employeeId: string;
}

export const List: FC<ListProps> = ({ employeeId }) => {
	/** State */
	const { year, goBack, goForward, setSelectedAbsenceId, selectedAbsenceId } =
		useVacationsTileStore();

	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { data, refetch } = useApi<{ absences: Absence[] }>(ax, {
		url: `/v2/dashboard/absences?year=${year}&employeeId=${employeeId}`,
		dependencies: ["dashboard", "absences", year.toString()],
	});

	/** Render */
	return (
		<Fragment>
			{isOpen && (
				<AddAbsenceModal
					isOpen={isOpen}
					onClose={onClose}
					employeeId={employeeId}
					onComplete={() => {
						refetch();
					}}
				/>
			)}
			<ListContainer
				heading={`${year}`}
				goBack={goBack}
				goForward={goForward}
				onAdd={onToggle}
			>
				{!(data?.absences?.length ?? 0) && (
					<BlankSlateContainer
						heading="Keine Abwesenheiten gefunden"
						subtext="Es wurden keine Abwesenheiten gefunden."
					/>
				)}
				{data?.absences.map((absence) => (
					<HStack
						key={absence.id}
						spacing={1}
						width="100%"
						padding={4}
						cursor="pointer"
						bg={absence.id === selectedAbsenceId ? colors.grayLight : undefined}
						onClick={() => {
							if (!absence.id) return;
							if (selectedAbsenceId === absence.id) setSelectedAbsenceId(null);
							else setSelectedAbsenceId(absence.id);
						}}
						_notLast={{
							borderBottomWidth: 1,
						}}
						_hover={{
							opacity: 0.8,
						}}
						_active={{
							opacity: 0.6,
						}}
					>
						<VStack
							spacing={1}
							width="100%"
						>
							<Text>
								{absenceTypeToReadable(absence.type)} /{" "}
								{absenceCategoryToReadable(absence.category)}
							</Text>
							<Heading size="sm">
								{moment().getSpanString(
									moment(absence.von),
									moment(absence.bis),
								)}
							</Heading>
						</VStack>
						<AbsenceStatusBadge absence={absence} />
					</HStack>
				))}
			</ListContainer>
		</Fragment>
	);
};
