import type { DocumentComment } from "@msuite/katana";
import { FormControl, FormLabel, Textarea } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormError } from "./schema";

export const DocumentCommentForm: FC = () => {
	/** Context */
	const methods = useFormContext<DocumentComment>();

	/** Render */
	return (
		<FormControl>
			<FormLabel>Kommentar</FormLabel>
			<Textarea
				placeholder="Kommentar hinzufügen..."
				{...methods.register("comment")}
			/>
			<FormError name="comment" />
		</FormControl>
	);
};
