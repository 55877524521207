import { Box, VStack, useUIContext } from "@msuite/picasso";
import { type FC, useEffect, useRef, useState } from "react";
import {
	Mention,
	MentionsInput,
	type SuggestionDataItem,
} from "react-mentions";
import { MentionSuggestion } from "./mention-suggestion";

/** Props Interface */
interface MentionInputProps {
	value: string;
	onChange: (value: string) => void;
	items: Array<SuggestionDataItem>;
	trigger?: string;
}

export const MentionInput: FC<MentionInputProps> = ({
	value: _value,
	onChange,
	items,
	trigger = "@",
}) => {
	/** State */
	const [value, setValue] = useState<string>(_value);

	/** Hooks */
	const inputRef = useRef<HTMLInputElement>(null);

	/** Context */
	const { colors } = useUIContext();

	/** Effects */
	useEffect(() => {
		onChange(value);
	}, [value]);

	/** Functions */
	function handleOnFocusInput() {
		inputRef.current?.focus();
	}

	const mentionStyle = {
		color: "transparent",
		backgroundColor: "rgba(47, 112, 219, 0.2)",
	};

	/** Render */
	return (
		<Box
			padding={4}
			rounded="lg"
			backgroundColor={colors.whiteAdjusted}
			borderWidth={1}
			cursor="text"
			onClick={handleOnFocusInput}
		>
			<MentionsInput
				value={value}
				onChange={(e) => setValue(e.target.value)}
				inputRef={inputRef}
				placeholder="Schreibe etwas und benutze das @-Symbol um andere Nutzer zu markieren."
				customSuggestionsContainer={(children) => (
					<VStack
						width="15rem"
						maxHeight="20rem"
						overflowY="scroll"
						rounded="lg"
						borderWidth={1}
						boxShadow="sm"
						py={2}
						backgroundColor={colors.whiteAdjusted}
						overflow="hidden"
						position="relative"
					>
						{children}
					</VStack>
				)}
				style={{
					suggestions: {
						background: "transparent",
					},
				}}
			>
				<Mention
					appendSpaceOnAdd
					renderSuggestion={MentionSuggestion}
					markup="@[__display__](userId:__id__)"
					displayTransform={(_, display) => {
						return `@${display}`;
					}}
					trigger={trigger}
					data={items}
					style={mentionStyle}
				/>
			</MentionsInput>
		</Box>
	);
};
