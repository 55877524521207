import { type Moment, moment } from "@msuite/katana";

export type BaseItemMode = "day" | "week";
export type KeyboardArrow =
	| "ArrowLeft"
	| "ArrowRight"
	| "ArrowUp"
	| "ArrowDown"
	| "Tab";

export function createSafeDefaultDate(input: string | undefined) {
	if (!input) return undefined;
	if (input.length > 3) return input;
	const valueAsNumber = Number(input);
	if (Number.isNaN(valueAsNumber)) return valueAsNumber.toString();
	const dateMoment = moment().isoWeek(valueAsNumber);
	const isValid = dateMoment.isValid();
	if (!isValid) return moment().format("WW-YYYY");
	return dateMoment.isBefore(moment().subtract(10, "weeks"), "week")
		? dateMoment.add(1, "year").format("WW-YYYY")
		: dateMoment.format("WW-YYYY");
}

export function getDateFormatFromMode(mode: BaseItemMode) {
	return mode === "day" ? "YYYY-MM-DD" : "WW-YYYY";
}

export function getReadableStringFromDate(dateString: string | null) {
	if (!dateString) return "Kein Datum";
	const inferredMode = inferDateMode(dateString);
	const dateFormat = getDateFormatFromMode(inferredMode);
	const momentObject = moment(dateString, dateFormat);
	const readableFormat = getReadableDateFormatFromMode(inferredMode);
	return momentObject.format(readableFormat);
}

export function getReadableDateFormatFromMode(mode: BaseItemMode) {
	if (mode === "day") return "dddd, DD.MM.YYYY";
	return "[Kalenderwoche] WW / YYYY";
}

export function getDatabaseDateFormatFromMode(mode: BaseItemMode) {
	return mode === "day" ? "YYYY-MM-DD" : "WW-YYYY";
}

export function getFocusTargetId(
	dateString: string,
	mode: BaseItemMode,
	key: KeyboardArrow,
): string | undefined {
	const dateFormat = getDateFormatFromMode(mode);
	const momentObject = moment(dateString, dateFormat);
	const isMonday = momentObject.isoWeekday() === 1;
	const isSunday = momentObject.isoWeekday() === 7;
	if (mode === "week") momentObject.isoWeekday(1);
	switch (key) {
		case "ArrowLeft":
			if (mode === "week")
				return momentObject
					.subtract(1, "week")
					.endOf("week")
					.format("YYYY-MM-DD");
			if (isMonday) return momentObject.format("WW-YYYY");
			return momentObject.subtract(1, "day").format(dateFormat);
		case "ArrowRight":
		case "Tab":
			if (mode === "week") return momentObject.format("YYYY-MM-DD");
			if (isSunday) return momentObject.add(1, "week").format("WW-YYYY");
			return momentObject.add(1, "day").format(dateFormat);
		case "ArrowUp":
			return momentObject.subtract(1, "week").format(dateFormat);
		case "ArrowDown":
			return momentObject.add(1, "week").format(dateFormat);
	}
}

export type ChangeBaseDateDirection = "prev" | "next" | "none";

export function changeBaseDate(
	dateString: string,
	baseDate: Moment,
): ChangeBaseDateDirection {
	const inferredMode = inferDateMode(dateString);
	const dateFormat = getDateFormatFromMode(inferredMode);

	const monthStart = baseDate.clone().startOf("month").format("YYYY-MM-DD");
	const monthEnd = baseDate.clone().endOf("month").format("YYYY-MM-DD");
	const momentObject = moment(dateString, dateFormat);

	if (inferredMode === "week") {
		const weekStart = momentObject.clone().startOf("week").format("YYYY-MM-DD");
		const weekEnd = momentObject.clone().endOf("week").format("YYYY-MM-DD");
		if (moment(weekEnd).isBefore(monthStart)) {
			return "prev";
		}
		if (moment(weekStart).isAfter(monthEnd)) return "next";
		return "none";
	}
	if (momentObject.isBefore(monthStart)) return "prev";
	if (momentObject.isAfter(monthEnd)) return "next";
	return "none";
}

/**
 * A function which infers the date format for a given string. The possible outcome is either 'YYYY-MM-DD' or 'WW-YYYY'. The inputs look either like 2023-01-01 or 01-2023.
 * @param dateString
 * @returns 'YYYY-MM-DD' or 'WW-YYYY'
 * @example
 * 		inferDateMode('2023-01-01') // 'YYYY-MM-DD'
 * 	  inferDateMode('01-2023') // 'WW-YYYY'
 */
export function inferDateMode(dateString: string): BaseItemMode {
	const split = dateString.split("-");
	if (split.length === 3) return "day";
	if (split.length === 2) return "week";
	return "day";
}
