import heic2any from "heic2any";

/**
 * This function is used to convert a heic file to a jpeg file.
 * @param files The files to convert
 * @returns {Promise<File[]>} The converted files
 */
export async function handleHeicConversion(files: File[]): Promise<File[]> {
	const converted: File[] = [];
	for (const file of files) {
		if (file.type === "image/heic") {
			const convertedFile: Blob = (await heic2any({
				blob: file,
				toType: "image/jpeg",
				quality: 1,
			})) as Blob;
			converted.push(blobToFile(convertedFile, file.name));
		} else {
			converted.push(file);
		}
	}
	return converted;
}

/**
 * This function is used to convert a blob to a file object.
 * @param blob
 * @param fileName
 * @returns {File}
 */
export function blobToFile(blob: Blob, fileName: string): File {
	const b: any = blob;
	b.lastModifiedDate = new Date();
	b.name = fileName;
	return blob as File;
}
