import { getOfferStateString, offerStateArray } from "@msuite/katana";
import { Box, HStack, Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

export const ProjectRequestStateDiagram: FC = () => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<HStack spacing={0}>
			{offerStateArray.map((state, index) => (
				<Box
					flex={1}
					backgroundColor={colors.grayDark}
					px={3}
					py={1}
					key={state}
					clipPath={`polygon(${index === offerStateArray.length - 1 ? "100%" : "97.5%"} 0%, 100% 50%, ${index === offerStateArray.length - 1 ? "100%" : "97.5%"} 100%, 0% 100%, ${index === 0 ? "0%" : "2.5%"} 50%, 0% 0%)`}
					className="project-request-state-diagram-item"
					cursor="pointer"
					borderLeftRadius={index === 0 ? "md" : "none"}
					borderRightRadius={
						index === offerStateArray.length - 1 ? "md" : "none"
					}
					mx="-0.15rem"
					ml={index === 0 ? 0 : "-0.15rem"}
					mr={index === offerStateArray.length - 1 ? 0 : "-0.15rem"}
				>
					<Text
						textTransform="uppercase"
						fontSize="xs"
						textAlign="center"
						fontWeight="700"
						color={colors.black}
					>
						{getOfferStateString(state)}
					</Text>
				</Box>
			))}
		</HStack>
	);
};
