import { db } from "@/core";
import type { RentListItem } from "@msuite/katana";
import {
	Box,
	Collapse,
	FormControl,
	FormLabel,
	Textarea,
	toast,
	useAuthContext,
} from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import type { FC } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDebounce } from "react-use";

/** Props Interface */
interface ResponseCommentProps {
	item: RentListItem;
}

export const ResponseComment: FC<ResponseCommentProps> = ({ item }) => {
	/** Context */
	const { userId } = useAuthContext();
	const { constructionSiteId } = item;

	/** State */
	const { register, control } = useForm({
		defaultValues: { note: item.note || "" },
	});
	const note = useWatch({ control, name: "note" });
	useDebounce(handleUpdateComment, 1000, [note]);

	/** Functions */
	async function handleUpdateComment() {
		try {
			if (!note) return;
			if (note === item.note) return;
			const rentListItemRef = doc(
				db,
				`user/${userId}/rentlist/${constructionSiteId}`,
			);
			toast.promise(updateDoc(rentListItemRef, { note }), {
				success: "Kommentar gespeichert",
				error: "Kommentar konnte nicht gespeichert werden",
				loading: "Kommentar wird gespeichert...",
			});
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<Box pt={2}>
			<Collapse in={["no", "maybe"].includes(item.answer)}>
				<form>
					<FormControl p={1}>
						<FormLabel>Kommentar</FormLabel>
						<Textarea
							placeholder="Begründung für die Antwort angeben..."
							{...register("note")}
						/>
					</FormControl>
				</form>
			</Collapse>
		</Box>
	);
};
