import { db } from "@/core";
import type { ProjectRequest as ProjectRequestType } from "@msuite/katana";
import {
	Box,
	Grid,
	InnerSiteLayout,
	SegmentedControl,
	VStack,
	useDocument,
	useTitle,
} from "@msuite/picasso";
import { useQueryState } from "nuqs";
import type { FC } from "react";
import { useParams } from "react-router-dom";
import { Calendar } from "./calendar";
import { Calls } from "./calls";
import { Dashboard } from "./dashboard";
import { Documents } from "./documents";
import { Email } from "./email";
import { Files } from "./files";
import { Meetingplaner } from "./meeting-planer";
import { Notes } from "./notes";
import { ProjectRequestHeader } from "./project-request-header";
import { ProjectRequestStateDiagram } from "./project-request-state-diagram";
import { Timeline } from "./timeline";

export const ProjectRequest: FC = () => {
	/** State */
	const [tab, setTab] = useQueryState("tab", {
		defaultValue: "dashboard",
		clearOnDefault: false,
	});

	/** Hooks */
	const { requestId } = useParams();
	const { data: projectRequest } = useDocument<ProjectRequestType>(db, {
		path: `projectRequests/${requestId}`,
		subscribe: true,
	});
	useTitle(`Anfrage - ${projectRequest?.projectName}`);

	/** Render */
	return (
		<InnerSiteLayout>
			<ProjectRequestHeader projectRequest={projectRequest} />
			<ProjectRequestStateDiagram />
			<SegmentedControl
				value={tab ?? "dashboard"}
				onChange={setTab}
				options={[
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Timeline",
						value: "timeline",
					},
					{
						label: "Notizen",
						value: "notes",
					},
					{
						label: "Meetingplaner",
						value: "meetingplaner",
					},
					{
						label: "Anrufe",
						value: "call",
					},
					{
						label: "E-Mail",
						value: "email",
					},
					{
						label: "Dateien",
						value: "files",
					},
					{
						label: "Dokumente",
						value: "documents",
					},
				]}
			/>
			<Grid
				flex={1}
				templateColumns="3fr 1fr"
				gap={6}
			>
				<ContentContainer>
					{tab === "dashboard" && <Dashboard />}
					{tab === "timeline" && <Timeline />}
					{tab === "notes" && <Notes />}
					{tab === "meetingplaner" && <Meetingplaner />}
					{tab === "call" && <Calls />}
					{tab === "email" && <Email />}
					{tab === "files" && <Files />}
					{tab === "documents" && <Documents />}
				</ContentContainer>
				<Calendar />
			</Grid>
		</InnerSiteLayout>
	);
};

/** Props Interface */
interface ContentContainerProps {
	children?: React.ReactNode;
}

export const ContentContainer: FC<ContentContainerProps> = ({ children }) => {
	return (
		<VStack
			spacing={0}
			overflowY="hidden"
			flexGrow={0}
			minH="0"
		>
			<Box
				height="0"
				flexGrow={1}
			>
				<VStack
					height="100%"
					overflowY="scroll"
					flexGrow={0}
					minH="0"
					spacing={0}
				>
					{children}
				</VStack>
			</Box>
		</VStack>
	);
};
