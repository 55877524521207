import { Box, Image } from "@msuite/picasso";
import type { FC } from "react";
import gruppeLogo from "./gruppe-logo.png";

export const Design: FC = () => {
	return (
		<Box
			position="absolute"
			top="0"
			right="0"
			overflow="hidden"
			width="100%"
			height="100%"
		>
			<Box
				position="absolute"
				top="0"
				right="5rem"
				width="5rem"
				zIndex={1}
			>
				<Image
					src={gruppeLogo}
					alt=""
				/>
			</Box>
			<Box
				position="absolute"
				bottom="0"
				right="0"
				width="60%"
				zIndex={0}
				opacity={0.04}
				sx={{ filter: "grayscale(100%)" }}
			>
				<Image
					src={gruppeLogo}
					alt=""
				/>
			</Box>
		</Box>
	);
};
