import { BlankSlateContainer, ContentContainer } from "@msuite/picasso";
import type { FC } from "react";
import { CallNoteController } from "./call-note-controller";
import { useProcessCallNotesModalContext } from "./context";
import { List } from "./list";

export const Content: FC = () => {
	/** Context */
	const { selectedCallNote } = useProcessCallNotesModalContext();

	/** Render */
	return (
		<ContentContainer>
			<List />
			{selectedCallNote ? (
				<CallNoteController />
			) : (
				<BlankSlateContainer
					heading="Offene Gesprächsnotiz auswählen"
					subtext="Wählen Sie eine Gesprächsnotiz aus, um sie zu bearbeiten."
				/>
			)}
		</ContentContainer>
	);
};
