import { yupResolver } from "@hookform/resolvers/yup";
import type { Ticket } from "@msuite/katana";
import {
	Box,
	Button,
	FormLabel,
	IconButton,
	Input,
	ModalButtonGroup,
	Popover,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	handleOnReject,
	useDisclosure,
	useUIContext,
} from "@msuite/picasso";
import { useEffect, useState, type FC } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TbTrashFilled } from "react-icons/tb";
import { DateController } from "./date-controller";
import { getReadableStringFromDate } from "./date-picker/handlers";
import { ticketDateFormSchema, type TicketDateForm } from "./schema";

/** Interfaces */
export type SelectedDateType = "date" | "week";

export interface OnUpdateTicketDateValue {
	selectedType: SelectedDateType;
	date: string;
	dateIsDeadline: boolean;
}

/** Props Interface */
interface AssignTicketDatePopoverProps {
	children?: React.ReactNode;
	currentTicket: Ticket;
	shouldRender?: boolean;
	onSubmit: (updateValue: TicketDateForm) => Promise<void> | void;
	onRemove: () => Promise<void> | void;
	withLabel?: boolean;
	label?: string;
}

export const AssignTicketDatePopover: FC<AssignTicketDatePopoverProps> = ({
	children,
	shouldRender = true,
	currentTicket,
	withLabel,
	label,
	onSubmit,
	onRemove,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** State */
	const [removeIsLoading, setRemoveIsLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();
	const datePickerDisclosure = useDisclosure();
	const earliestFromDisclosure = useDisclosure();

	const methods = useForm<TicketDateForm>({
		// @ts-ignore
		resolver: yupResolver(ticketDateFormSchema),
		defaultValues: {
			date: currentTicket?.date ?? "",
			dateIsDeadline: currentTicket?.date_is_deadline ?? false,
			dateEarliestFrom: currentTicket?.date_earliest_from ?? "",
			withEarliestFrom: !!currentTicket?.date_earliest_from,
		},
	});
	const [date] = useWatch({ control: methods.control, name: ["date"] });

	const currentValueString = getReadableStringFromDate(date);

	async function handleOnSubmit() {
		try {
			const isValid = await methods.trigger();
			if (!isValid) return handleOnReject(methods.formState.errors);
			const data = methods.getValues();
			setIsLoading(true);
			onSubmit(data);
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleOnRemove() {
		try {
			setRemoveIsLoading(true);
			methods.reset({});
			await onRemove();
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setRemoveIsLoading(false);
		}
	}

	/** Effects */
	useEffect(() => {
		if (!isOpen) {
			earliestFromDisclosure.onClose();
			datePickerDisclosure.onClose();
		}
	}, [isOpen]);

	/** Render */
	return (
		<FormProvider {...methods}>
			<Popover
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				placement="end"
				closeOnBlur={
					!datePickerDisclosure.isOpen && !earliestFromDisclosure.isOpen
				}
				closeOnEsc={
					!datePickerDisclosure.isOpen && !earliestFromDisclosure.isOpen
				}
			>
				<PopoverTrigger>
					{children ?? (
						<div>
							{withLabel && <FormLabel>{label}</FormLabel>}
							<Input
								readOnly
								cursor="pointer"
								value={!date ? "Kein Datum" : currentValueString}
							/>
						</div>
					)}
				</PopoverTrigger>
				{shouldRender &&
					createPortal(
						<PopoverContent>
							<PopoverCloseButton />
							<PopoverHeader>Datum hinzufügen</PopoverHeader>
							<PopoverBody>
								<DateController
									disclosure={datePickerDisclosure}
									earliestFromDisclosure={earliestFromDisclosure}
								/>
								<Box sx={{ height: 5 }} />
								<ModalButtonGroup>
									<IconButton
										color={colors.red}
										icon={<TbTrashFilled />}
										onClick={handleOnRemove}
										isLoading={removeIsLoading}
										aria-label="Ticket entfernen"
									/>
									<Button
										width="100%"
										isLoading={isLoading}
										onClick={handleOnSubmit}
									>
										Übernehmen
									</Button>
								</ModalButtonGroup>
							</PopoverBody>
						</PopoverContent>,
						document.body,
					)}
			</Popover>
		</FormProvider>
	);
};
