import { SurveyContext } from "@/context/survey";
import { Box } from "@msuite/picasso";
import { AnimatePresence } from "framer-motion";
import { type FC, useContext } from "react";
import { Design } from "./design";
import { EndPage } from "./end-page";
import { LoadingScreen } from "./loading-screen";
import { QuestionSwitcher } from "./question";
import { QuestionWrapper } from "./question/question-wrapper";
import { StartPage } from "./start-page";

export const Canvas: FC = () => {
	return (
		<Box
			position="absolute"
			left="0"
			right="0"
			top="0"
			bottom="0"
			overflow="hidden"
		>
			<Design />
			<CanvasSwitcher />
		</Box>
	);
};

const CanvasSwitcher: FC = () => {
	/** Context */
	const { activeElement } = useContext(SurveyContext);
	if (!activeElement) return <LoadingScreen />;
	if (activeElement?.type === "start_page") return <StartPage />;
	if (activeElement?.type === "end_page") return <EndPage />;
	if (activeElement?.type === "question")
		return (
			<AnimatePresence mode="popLayout">
				<QuestionWrapper key={activeElement.questionId}>
					<QuestionSwitcher />
				</QuestionWrapper>
			</AnimatePresence>
		);
	return <p>error</p>;
};
