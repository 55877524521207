import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { HStack } from "@msuite/picasso";
import type { FC } from "react";
import { AssignDateButton } from "./assign-date-button";
import { DeleteTicketButton } from "./delete-ticket-button";
import { NotAssignButton } from "./not-assign-button";
import { QuickAssignButton } from "./quick-assign-button";

export const ActionBar: FC = () => {
	/** Context */
	const { userIsOwner } = useConstructionSiteCardContext();

	if (!userIsOwner) return null;

	/** Render */
	return (
		<HStack
			justifySelf="flex-end"
			spacing={1.5}
			pr={1}
		>
			<DeleteTicketButton />
			<AssignDateButton />
			<NotAssignButton />
			<QuickAssignButton />
		</HStack>
	);
};
