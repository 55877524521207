import { db } from "@/core";
import { TicketsForm } from "@/forms/tickets-form";
import type {
	ConstructionSite,
	ConstructionSiteContact,
	Workspace,
} from "@msuite/katana";
import { getCity } from "@msuite/katana";
import {
	AutoComplete,
	type AutoCompleteOption,
	Box,
	DocumentString,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Switch,
	VStack,
	useApiKey,
	useAuthContext,
	useCollection,
} from "@msuite/picasso";
import { type FC, Fragment, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TbArrowRight } from "react-icons/tb";
import { ClientAutoCompleteItem } from "../../components/construction-site/client-auto-complete-item";
import { ManagersHandler } from "./managers-handler";
import { FormError } from "./schema";
import { TrimbleConnectAdder } from "./trimble-connect-adder";

export type ConstructionSiteFormView =
	| "construction-site"
	| "tickets"
	| "existing-search-box";

/** Props Interface */
interface ConstructionSiteFormProps {
	currentView: ConstructionSiteFormView;
	generateNumber?: boolean;
	isEdit?: boolean;
	modalRef?: React.RefObject<HTMLDivElement>;
	initialFocusTicketId?: string;
	constructionSiteId: string | undefined;
	setGenerateNumber?: (value: boolean) => void;
}

export const ConstructionSiteForm: FC<ConstructionSiteFormProps> = ({
	currentView,
	generateNumber,
	setGenerateNumber,
	initialFocusTicketId,
	isEdit,
	modalRef,
	constructionSiteId,
}) => {
	/** Context */
	const { workspaceIds } = useAuthContext();
	const {
		register,
		watch,
		formState,
		setValue,
		getValues,
		control,
		resetField,
	} = useFormContext<ConstructionSite>();
	const [projectName, street, streetNumber, clientId, legacyClient] = useWatch({
		control,
		name: ["bauvorhaben", "strasse", "hausnummer", "clientId", "auftraggeber"],
	});
	const selectedManagers = watch("bauleiter");
	const projectNameIsDirty = formState.dirtyFields.bauvorhaben;

	/** State */
	const [showLegacyClientField, setShowLegacyClientField] = useState<boolean>(
		!!legacyClient,
	);

	/** Hooks */
	const apiKey = useApiKey("zipCodeBase");
	const { data: contacts } = useCollection<ConstructionSiteContact>(db, {
		path: "_contactBook",
		orderBy: "name",
		subscribe: true,
	});

	/** Functions */
	async function handleUpdateCity() {
		try {
			const zipCode = getValues("plz");
			if (!zipCode) throw new Error("No zip code provided");
			const city = await getCity(zipCode, apiKey);
			setValue("ort", city);
		} catch (error) {
			console.error(error);
		}
	}

	function handleSelectClientId(
		changeItem: AutoCompleteOption<ConstructionSiteContact> | undefined,
	) {
		if (!changeItem) return;
		if (!changeItem?.value) return;
		setValue("clientId", changeItem?.value);
		setValue("auftraggeber", undefined);
		resetField("auftraggeber");
		setShowLegacyClientField(false);
	}

	function handleOnClickEmptyElement(searchValue: string) {
		setShowLegacyClientField(true);
		setTimeout(() => {
			setValue("auftraggeber", searchValue);
			setValue("clientId", undefined);
		}, 0);
		const legacyClientElement = document.getElementById(
			"legacy-client-element",
		);
		if (legacyClientElement) {
			setTimeout(() => {
				legacyClientElement.scrollIntoView({ behavior: "smooth" });
				legacyClientElement.focus();
			}, 0);
		}
	}

	/** Effects */
	useEffect(() => {
		if (projectNameIsDirty) return;
		if (getValues("id") && getValues("bauvorhaben")) return;
		if (street === undefined && streetNumber === undefined) return;
		const generatedProjectName = `${street} ${streetNumber}`.trim();
		if (!generatedProjectName) return;
		setValue("bauvorhaben", `${street} ${streetNumber}`);
	}, [projectName, street, streetNumber, projectNameIsDirty, setValue]);

	/** Fallback */
	if (currentView === "tickets")
		return (
			<TicketsForm
				modalRef={modalRef}
				initialFocusTicketId={initialFocusTicketId}
				constructionSiteId={constructionSiteId}
			/>
		);

	/** Render */
	return (
		<Fragment>
			<VStack>
				<FormControl>
					<HStack justifyContent="space-between">
						<FormLabel>Nummer</FormLabel>
						{generateNumber !== undefined &&
							!isEdit &&
							setGenerateNumber !== undefined && (
								<Switch
									isChecked={generateNumber}
									flexDirection="row-reverse"
									onChange={() => {
										setGenerateNumber(!generateNumber);
										setValue("nummer", "");
									}}
								>
									Automatisch vergeben
								</Switch>
							)}
					</HStack>
					<Input
						autoFocus
						isDisabled={generateNumber}
						placeholder={
							generateNumber ? "wird automatisch vergeben" : "z.B. 222 4723"
						}
						{...register("nummer")}
					/>
					<FormError name="nummer" />
				</FormControl>

				<FormControl>
					<FormLabel>Auftraggeber</FormLabel>
					<AutoComplete
						alwaysShowEmptyElement
						key={clientId}
						placeholder="Auftraggeber suchen"
						defaultValue={
							clientId
								? {
										value: clientId,
										label:
											contacts.find((contact) => contact.id === clientId)
												?.name ?? "N.A.",
									}
								: undefined
						}
						options={contacts.map((contact) => ({
							value: contact.id,
							item: contact,
							label: contact.name,
						}))}
						renderItem={ClientAutoCompleteItem}
						onChange={handleSelectClientId}
						emptyElement={{
							icon: <TbArrowRight />,
							label: "Mit vorläufigem Auftraggeber fortfahren.",
							onClick: handleOnClickEmptyElement,
						}}
					/>
					<FormError name="clientId" />
				</FormControl>

				{showLegacyClientField && (
					<FormControl>
						<FormLabel>Vorläufiger Auftraggeber</FormLabel>
						<Input
							{...register("auftraggeber")}
							id="legacy-client-element"
						/>
						<FormHelperText>
							Diese Angabe wird nur vorübergehend verwendet und ist durch einen
							Kontakt aus der Datenbank zu ersetzen.
						</FormHelperText>
					</FormControl>
				)}

				<Grid
					gridTemplateColumns="2fr 1fr"
					gridColumnGap={6}
				>
					<FormControl>
						<FormLabel>Straße</FormLabel>
						<Input
							placeholder="z.B. Roedernallee"
							{...register("strasse")}
						/>
						<FormError name="strasse" />
					</FormControl>
					<FormControl>
						<FormLabel>Nr.</FormLabel>
						<Input
							placeholder="z.B. 9"
							{...register("hausnummer")}
						/>
						<FormError name="hausnummer" />
					</FormControl>
				</Grid>

				<Grid
					gridTemplateColumns="1fr 1fr"
					gridColumnGap={6}
				>
					<FormControl>
						<FormLabel>PLZ</FormLabel>
						<Input
							placeholder="z.B. 13407"
							maxLength={5}
							{...register("plz")}
							onBlur={handleUpdateCity}
						/>
						<FormError name="plz" />
					</FormControl>
					<FormControl>
						<FormLabel>Ort</FormLabel>
						<Input
							placeholder="z.B. Berlin"
							{...register("ort")}
						/>
						<FormError name="ort" />
					</FormControl>
				</Grid>

				<FormControl>
					<FormLabel>Bauvorhaben</FormLabel>
					<Input
						placeholder="z.B. Roedernallee 9"
						{...register("bauvorhaben")}
					/>
					<FormError name="bauvorhaben" />
				</FormControl>

				<ManagersHandler handleManagersFrom="bauleiter" />
				<ManagersHandler
					handleManagersFrom="currentDeputies"
					selectableFilterFn={(manager) => {
						return !selectedManagers?.includes(manager.id);
					}}
				/>

				<FormControl>
					<FormLabel>Hinweis</FormLabel>
					<Input
						{...register("note")}
						placeholder="z.B. nur über Einfahrt Seitenstraße"
					/>
				</FormControl>

				<FormControl>
					<FormLabel>Plangruppe</FormLabel>
					<Select
						placeholder="Plangruppe auswählen"
						key={workspaceIds?.join("")}
						{...register("plangruppe")}
					>
						{(workspaceIds ?? []).map((workspaceId) => (
							<option
								key={workspaceId}
								value={workspaceId}
							>
								<DocumentString<Workspace>
									db={db}
									path={`plangruppen/${workspaceId}`}
									fields={["name"]}
								/>
							</option>
						))}
					</Select>
					<FormError name="plangruppe" />
				</FormControl>
				<TrimbleConnectAdder />
				<Box style={{ height: "10vh" }} />
			</VStack>
		</Fragment>
	);
};
