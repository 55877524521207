import { ax } from "@/core";
import { ResendCampaignInvitationModal } from "@/modals/misc/resend-campaign-invitation-modal";
import type { CampaignAnswer } from "@msuite/katana";
import { HStack, IconButton, Tooltip, useDisclosure } from "@msuite/picasso";
import { type FC, useState } from "react";
import { IoCheckmark, IoClose, IoRepeatOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";

/** Props Interface */
interface RowActionsProps {
	campaignAnswer: CampaignAnswer;
}

export const RowActions: FC<RowActionsProps> = ({ campaignAnswer }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [answerInProcess, setAnswerInProcess] = useState<
		"yes" | "no" | undefined
	>(undefined);

	/** Hooks */
	const params = useParams();
	const campaignId = params.campaignId ?? undefined;
	const { isOpen, onClose, onOpen } = useDisclosure();

	/** Functions */
	async function handleOnResendInvitation() {
		try {
			if (!campaignId) return;
			setIsLoading(true);
			await ax.post(`/v2/email-service/campaigns/${campaignId}/invite`, {
				data: {
					values: campaignAnswer,
				},
			});
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	async function handleOnAnswer(_answer: CampaignAnswer["answer"]) {
		try {
			setAnswerInProcess(_answer);
			if (_answer === undefined) throw new Error("No answer");
			const email = campaignAnswer.email;
			const answer = _answer;
			await ax.post(`/v2/campaigns/${campaignId}/answer?format=utf-8`, {
				email,
				answer,
			});
		} catch (error) {
			console.error(error);
		} finally {
			setAnswerInProcess(undefined);
		}
	}

	/** Render */
	return (
		<HStack
			spacing={2}
			justify="flex-end"
		>
			<IconButton
				size="xs"
				icon={<IoCheckmark />}
				aria-label="yes"
				onClick={() => handleOnAnswer("yes")}
				isLoading={answerInProcess === "yes"}
			/>
			<IconButton
				size="xs"
				icon={<IoClose />}
				aria-label="no"
				onClick={() => handleOnAnswer("no")}
				isLoading={answerInProcess === "no"}
			/>
			<Tooltip label="Erneut versenden">
				<IconButton
					size="xs"
					icon={<IoRepeatOutline />}
					aria-label="resend email"
					onClick={onOpen}
					isLoading={isLoading}
				/>
			</Tooltip>
			<ResendCampaignInvitationModal
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={handleOnResendInvitation}
				email={campaignAnswer.email}
			/>
		</HStack>
	);
};
