import type {
	SubcontractorPublishEntry,
	SubcontractorTimesheetSection,
} from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface IProcessTimesheetsModalContext {
	refetch: () => Promise<unknown>;
	selectedPublishEntry:
		| {
				entry: SubcontractorPublishEntry;
				constructionSiteId: string;
				subcontractorId: string;
		  }
		| undefined;
	setSelectedPublishEntry: (
		id:
			| {
					entry: SubcontractorPublishEntry;
					constructionSiteId: string;
					subcontractorId: string;
			  }
			| undefined,
	) => void;
	timesheetSections: SubcontractorTimesheetSection[];
}

/** Context */
export const ProcessTimesheetsModalContext =
	createContext<IProcessTimesheetsModalContext>(
		{} as IProcessTimesheetsModalContext,
	);

/** Hooks */
export function useProcessTimesheetsModalContext() {
	return useContext(ProcessTimesheetsModalContext);
}
