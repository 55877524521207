import type { SubcontractorTimesheetSection } from "@msuite/katana";
import { VStack } from "@msuite/picasso";
import type { FC } from "react";
import { SubcontractorSectionConstructionSiteHeader } from "./subcontractor-section-construction-site-header";
import { SubcontractorSectionHeader } from "./subcontractor-section-header";

/** Props Interface */
interface SubcontractorSectionProps {
	section: SubcontractorTimesheetSection;
}

export const SubcontractorSection: FC<SubcontractorSectionProps> = ({
	section,
}) => {
	/** Render */
	return (
		<VStack
			paddingTop={3}
			spacing={0}
			_notLast={{
				borderBottomWidth: 1,
			}}
		>
			<SubcontractorSectionHeader subcontractorId={section.subunternehmer_id} />
			{section.baustellen?.map((constructionSiteSection) => (
				<SubcontractorSectionConstructionSiteHeader
					key={constructionSiteSection.baustelle_id}
					subcontractorId={section.subunternehmer_id}
					constructionSiteSection={constructionSiteSection}
				/>
			))}
		</VStack>
	);
};
