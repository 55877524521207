import { Button, HStack } from "@msuite/picasso";
import type { FC } from "react";
import { ProgressBar } from "./progress-bar";

/** Props Interface */
interface SurveyControlsProps {
	onCancel: () => void;
}

export const SurveyControls: FC<SurveyControlsProps> = ({ onCancel }) => {
	/** Render */
	return (
		<HStack
			width="100%"
			justifyContent="flex-start"
		>
			<Button
				variant="ghost"
				size="xs"
				onClick={onCancel}
			>
				Zurück
			</Button>
			<ProgressBar />
		</HStack>
	);
};
