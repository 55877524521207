import { createContext, useContext } from "react";

/** Interface */
interface FileGalleryModalContext {
	files: File[];
	setFiles: (files: File[]) => void;
	existingFileNames: string[];
	existingRelatedFileNames: string[];
	path: string;
	onDeleteCallback: (fileName: string) => Promise<void> | void;
}

/** Context */
export const FileGalleryModalContext = createContext<FileGalleryModalContext>(
	{} as FileGalleryModalContext,
);

/** Hooks */
export const useFileGalleryModalContext = () => {
	return useContext(FileGalleryModalContext);
};
