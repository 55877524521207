import { moment } from "@msuite/katana";
import {
	Divider,
	FormControl,
	FormLabel,
	HStack,
	Switch,
	Text,
	VStack,
	type useDisclosure,
} from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DatePicker, type OnChangeDate } from "./date-picker";
import { getDateFormatFromMode } from "./date-picker/handlers";
import { FormError, type TicketDateForm } from "./schema";

/** Props Interface */
interface DateControllerProps {
	disclosure: ReturnType<typeof useDisclosure>;
	earliestFromDisclosure: ReturnType<typeof useDisclosure>;
	containerRef?: React.RefObject<HTMLDivElement>;
}

export const DateController: FC<DateControllerProps> = ({
	disclosure,
	earliestFromDisclosure,
}) => {
	/** Context */
	const { setValue, control } = useFormContext<TicketDateForm>();
	const [dateIsDeadline, withEarliestFrom, date, dateEarliestFrom] = useWatch({
		control,
		name: ["dateIsDeadline", "withEarliestFrom", "date", "dateEarliestFrom"],
	});

	/** Functions */
	function handleChangeDate(data: OnChangeDate) {
		const dateFormat = getDateFormatFromMode(data.mode);
		setValue("date", data.date.format(dateFormat));
	}

	function handleChangeEarliestFromDate(data: OnChangeDate) {
		const dateFormat = getDateFormatFromMode(data.mode);
		setValue("dateEarliestFrom", data.date.format(dateFormat));
	}

	/** Render */
	return (
		<VStack spacing={4}>
			<VStack spacing={2}>
				<FormControl isDisabled={!withEarliestFrom}>
					<FormLabel autoFocus>Frühstens ab auswählen</FormLabel>
					<DatePicker
						defaultValue={dateEarliestFrom}
						disclosure={earliestFromDisclosure}
						onChange={handleChangeEarliestFromDate}
						minValue={moment().format("YYYY-MM-DD")}
					/>
					<FormError name="dateEarliestFrom" />
				</FormControl>
				<FormControl>
					<HStack
						justify="space-between"
						cursor="pointer"
						onClick={() => setValue("withEarliestFrom", !withEarliestFrom)}
					>
						<Text fontSize="sm">Frühestens ab</Text>
						<Switch
							pointerEvents="none"
							size="sm"
							isChecked={withEarliestFrom}
						/>
					</HStack>
					<FormError name="withEarliestFrom" />
				</FormControl>
			</VStack>
			<Divider />
			<VStack spacing={2}>
				<FormControl>
					<FormLabel>Datum auswählen</FormLabel>
					<DatePicker
						defaultValue={date}
						disclosure={disclosure}
						onChange={handleChangeDate}
						minValue={moment().format("YYYY-MM-DD")}
					/>
					<FormError name="date" />
				</FormControl>
				<FormControl>
					<HStack
						justify="space-between"
						cursor="pointer"
						onClick={() => setValue("dateIsDeadline", !dateIsDeadline)}
					>
						<Text fontSize="sm">spätestens bis</Text>
						<Switch
							pointerEvents="none"
							size="sm"
							isChecked={dateIsDeadline}
						/>
					</HStack>
					<FormError name="dateIsDeadline" />
				</FormControl>
			</VStack>
		</VStack>
	);
};
