import type { CallNote } from "@msuite/katana";
import { Divider, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { CallNoteOverviewContext } from "./context";
import { OverviewCallbackInformation } from "./overview-callback-information";
import { OverviewCallerInformation } from "./overview-caller-information";
import { OverviewCommentBox } from "./overview-comment-box";
import { OverviewCompanyInformation } from "./overview-company-information";
import { OverviewFurtherNotes } from "./overview-further-notes";
import { OverviewReasonSelect } from "./overview-reason-select";
import { OverviewVisibilityInformation } from "./overview-visibility-information";

/** Props Interface */
interface CallNoteOverviewProps {
	callNote: CallNote;
	withCommentBox?: boolean;
}

export const CallNoteOverview: FC<CallNoteOverviewProps> = ({
	callNote,
	withCommentBox,
}) => {
	return (
		<CallNoteOverviewContext.Provider value={{ callNote }}>
			<VStack>
				<OverviewCompanyInformation />
				<Divider />
				<OverviewCallerInformation />
				<Divider />
				<OverviewReasonSelect />
				<Divider />
				<OverviewCallbackInformation />
				<Divider />
				<OverviewVisibilityInformation />
				<Divider />
				<OverviewFurtherNotes />
				{withCommentBox && <OverviewCommentBox />}
			</VStack>
		</CallNoteOverviewContext.Provider>
	);
};
