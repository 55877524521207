import { ax, db } from "@/core";
import type {
	BaustelleYearlyStatement,
	StatementAnswerOptions,
	StatementAnswerType,
	StatementCleanedSite,
} from "@msuite/katana";
import { mapStatementAnswerToReadable, moment } from "@msuite/katana";
import {
	Grid,
	HStack,
	Heading,
	Select,
	Text,
	VStack,
	WorkspaceBadge,
	toast,
	useApi,
	useAuthContext,
	useUIContext,
} from "@msuite/picasso";
import { useYearlyStatementModalContext } from "context/yearly-statement-modal";
import {
	addDoc,
	collection,
	deleteDoc,
	getDocs,
	limit,
	query,
	runTransaction,
	updateDoc,
	where,
} from "firebase/firestore";
import { useInView } from "framer-motion";
import { type FC, useRef } from "react";
import { Responses } from "./responses";
import { useStatements } from "./use-statements";

/** Props Interface */
interface ResponseCardProps extends StatementCleanedSite {}

export const ResponseCard: FC<ResponseCardProps> = ({ site }) => {
	/** Context */
	const { colors } = useUIContext();
	const { user } = useAuthContext();
	const { currentView } = useYearlyStatementModalContext();

	/** Hooks */
	const { responses } = useStatements(site?.id);
	const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
	const isInView = useInView(ref, { once: true });
	const { data } = useApi<{
		success: boolean;
		options: StatementAnswerOptions;
	}>(ax, {
		url: `/jahresendabrechnung/baustelle/${site.id}/options`,
		dependencies: ["yearly-statement-options", site?.id ?? ""],
		disabled: !isInView,
	});

	/** Constants */
	const last_own_response = responses?.find((r) => r?.created_by === user?.id);

	/** Filter Fallback */
	if (
		currentView !== "all" &&
		last_own_response?.answer !== "no-response" &&
		last_own_response !== undefined
	)
		return null;

	/** Functions */
	async function perform(answer: StatementAnswerType) {
		try {
			const collection_ref = collection(
				db,
				"baustellen",
				site.id || "",
				"yearly_statement",
			);
			const statement: BaustelleYearlyStatement = {
				baustelleId: site.id ?? "",
				bauleiter: site.bauleiter || [],
				year: moment().year(),
				created_by: user?.id || "N.A.",
				created_at: moment().toISOString(),
				answer: answer,
			};
			await runTransaction(db, async () => {
				const existing_statement_q = query(
					collection_ref,
					where("year", "==", moment().year()),
					where("created_by", "==", user?.id || "N.A."),
					limit(1),
				);
				const existing_statements = await getDocs(existing_statement_q);
				if (!answer && !existing_statements.empty)
					deleteDoc(existing_statements.docs[0].ref);
				else if (!answer) return;
				if (!existing_statements.empty)
					await updateDoc(existing_statements.docs?.[0].ref, { ...statement });
				else await addDoc(collection_ref, statement);
				toast.success("Antwort gespeichert");
			});
		} catch (error) {
			console.error(error);
			toast.error("Fehler beim Speichern der Antwort");
		}
	}

	/** Render */
	return (
		<VStack
			bg={colors.whiteAdjusted}
			alignItems="stretch"
			p="1rem"
			rounded="xl"
			borderWidth={1}
			spacing={3}
			className="unanswered-response-card"
			ref={ref}
		>
			<Grid templateColumns="2.5fr 1fr">
				<VStack
					alignItems="stretch"
					spacing={2}
					pr="5rem"
				>
					<HStack justify="space-between">
						<Heading
							size="sm"
							noOfLines={1}
						>
							{site.bauvorhaben || "N.A."}
						</Heading>
						<WorkspaceBadge workspaceId={site?.plangruppe} />
					</HStack>
					<Text
						fontSize="sm"
						color="gray.500"
						noOfLines={1}
						lineHeight="1"
					>
						{site.nummer} / {site.auftraggeber}
					</Text>
					<HStack justify="space-between">
						<Text
							fontSize="sm"
							color="gray.500"
							noOfLines={1}
							lineHeight="1"
						>
							{site.strasse || "--"} {site.hausnummer || "--"},{" "}
							{site.plz || "--"} {site.ort || "--"}
						</Text>
					</HStack>
				</VStack>
				<Select
					value={last_own_response?.answer}
					placeholder="Bitte Antwort wählen"
					onChangeCapture={(e) => {
						perform((e.target as any).value as StatementAnswerType);
					}}
				>
					{data?.options.map((val) => (
						<option
							key={val.option}
							disabled={val.isDisabled}
							value={val.option}
						>
							{mapStatementAnswerToReadable(val.option)}
						</option>
					))}
				</Select>
			</Grid>
			<Responses responses={responses} />
		</VStack>
	);
};
