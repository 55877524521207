import { createContext, useContext } from "react";

/** Props Interface */
interface IAppContext {
	activeWorkspace: string | null;
	setActiveWorkspace: (workspace: string) => void;
}

/** Context */
export const AppContext = createContext<IAppContext>({} as IAppContext);

/** Hook */
export const useAppContext = () => {
	return useContext(AppContext);
};
