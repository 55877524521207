import type { Ticket } from "@msuite/katana";
import type { TicketCardModule } from "components/card/ticket-card";
import type { TicketDateMode } from "components/card/ticket-card/ticket-date";
import type { useTicketMetaInformation } from "components/card/ticket-card/use-ticket-meta-information";
import { createContext, useContext } from "react";

/** Interface */
interface ITicketsContext {
	selectedTicketIndices: number[];
	setSelectedTicketIndices: (indices: number[]) => void;
	filteredTickets: Ticket[];
}

interface ITicketCardContext {
	ticket: Ticket;
	modules: TicketCardModule[];
	ticketIndex: number;
	ticketMetaInformation: ReturnType<typeof useTicketMetaInformation>;
	handleOnDeleteTicketDate: (
		ticketIds: string[],
		mode: TicketDateMode,
		withAlternativeDelete?: boolean,
	) => Promise<void>;
}

/** Context */
export const TicketCardContext = createContext<ITicketCardContext>(
	{} as ITicketCardContext,
);
export const TicketsContext = createContext<ITicketsContext>(
	{} as ITicketsContext,
);

/** Hooks */
export const useTicketCardContext = () => {
	return useContext(TicketCardContext);
};

export const useTicketsContext = () => {
	return useContext(TicketsContext);
};
