import { HandleSurveysModal } from "@/components/handle-surveys";
import { useAppContext } from "@/context/app";
import { ProcessCallNotesModal } from "@/modals/call-notes/process-call-notes-modal";
import { AbsencesOverviewModal } from "@/modals/employees/absences-overview-modal";
import { HandleRentListModal } from "@/modals/misc/handle-rent-list-modal";
import { YearlyStatementModal } from "@/modals/misc/yearly-statement-modal";
import { ProcessTimesheetsModal } from "@/modals/subcontractors/process-timesheets-modal";
import { DashboardWrapper, useAuthContext, useTitle } from "@msuite/picasso";
import { Fragment, useEffect } from "react";
import { DeputiesControl } from "./deputies-control";
import { useDashboard } from "./use-dashboard";

export const Home = (): JSX.Element => {
	/** Context */
	const { workspaceIds, userId } = useAuthContext();
	const { activeWorkspace, setActiveWorkspace } = useAppContext();
	const {
		tiles,
		editTimesheetsModal,
		editVacationsModal,
		editCallNotesModal,
		editRentListModal,
		editSurveysModal,
		editYearlyStatementModal,
		alerts,
		handleOnRefetchAlerts,
	} = useDashboard();

	/** Hooks */
	const queryParams = window.location.search;
	useTitle("Dashboard");

	/** Effects */
	useEffect(() => {
		if (queryParams.includes("vacationsTileOpen=true"))
			editVacationsModal.onOpen();
	}, [queryParams]);

	/** Fallback */
	if (!activeWorkspace) return <div>Loading...</div>;

	/** Render */
	return (
		<Fragment>
			<DashboardWrapper
				workspaceIds={workspaceIds}
				activeWorkspace={activeWorkspace}
				setActiveWorkspace={setActiveWorkspace}
				tiles={tiles}
				alerts={alerts}
			/>
			<ProcessTimesheetsModal
				isOpen={editTimesheetsModal.isOpen}
				onClose={editTimesheetsModal.onClose}
			/>
			<ProcessCallNotesModal
				isOpen={editCallNotesModal.isOpen}
				onClose={editCallNotesModal.onClose}
			/>
			<AbsencesOverviewModal
				isOpen={editVacationsModal.isOpen}
				onClose={editVacationsModal.onClose}
				employeeId={userId || undefined}
			/>
			<HandleRentListModal
				isOpen={editRentListModal.isOpen}
				onClose={editRentListModal.onClose}
				onComplete={handleOnRefetchAlerts}
			/>
			<HandleSurveysModal
				isOpen={editSurveysModal.isOpen}
				onClose={async () => {
					await handleOnRefetchAlerts();
					editSurveysModal.onClose();
				}}
			/>
			<YearlyStatementModal
				isOpen={editYearlyStatementModal.isOpen}
				onClose={editYearlyStatementModal.onClose}
			/>
			<DeputiesControl />
		</Fragment>
	);
};
