import { FormControl, FormLabel, Grid, Text } from "@msuite/picasso";
import type { FC } from "react";
import { CallReasonLabels } from "../form/schema";
import { useCallNoteOverviewContext } from "./context";
import { OverviewReasonConstructionSite } from "./overview-reason-construction-site";
import { OverviewReasonOffer } from "./overview-reason-offer";
import { OverviewReasonOther } from "./overview-reason-other";

export const OverviewReasonSelect: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Render */
	return (
		<Grid
			templateAreas="repeat(2, 1fr)"
			columnGap={6}
			rowGap={6}
		>
			<FormControl>
				<FormLabel>Grund des Anrufs</FormLabel>
				<Text>{CallReasonLabels[callNote.reason.type]}</Text>
			</FormControl>
			{callNote.reason.type === "construction-site" && (
				<OverviewReasonConstructionSite />
			)}
			{callNote.reason.type === "offer" && <OverviewReasonOffer />}
			{callNote.reason.type === "other" && <OverviewReasonOther />}
			<FormControl>
				<FormLabel>Beschreibung</FormLabel>
				<Text whiteSpace="pre-line">{callNote.reason.description}</Text>
			</FormControl>
		</Grid>
	);
};
