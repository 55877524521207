import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { SketchupConnectIFrame } from "./sketchup-connect-iframe";
import { TrimbleConnectIFrame } from "./trimble-connect-iframe";

/** Props Interface */
interface TrimbleConnectModalProps {
	isOpen: boolean;
	onClose: () => void;
	trimbleLink: string;
}

export const TrimbleConnectModal: FC<TrimbleConnectModalProps> = ({
	isOpen,
	onClose,
	trimbleLink,
}) => {
	const linkType = getTrimbleLinkType(trimbleLink);

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnEsc
		>
			<ModalOverlay />
			<ModalContent maxWidth="80rem">
				<ModalCloseButton />
				<ModalHeader>3D-Modell</ModalHeader>
				<ModalBody>
					{isOpen && (
						<Fragment>
							{linkType === "trimble-connect" && (
								<TrimbleConnectIFrame trimbleLink={trimbleLink} />
							)}
							{linkType === "sketchup" && (
								<SketchupConnectIFrame trimbleLink={trimbleLink} />
							)}
						</Fragment>
					)}
				</ModalBody>
				<ModalFooter>
					<Button onClick={onClose}>Fertig</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

function getTrimbleLinkType(trimbleLink: string) {
	if (trimbleLink.includes("web.connect.trimble.com")) return "trimble-connect";
	if (trimbleLink.includes("app.sketchup.com")) return "sketchup";
	return "none";
}
