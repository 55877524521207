import { db } from "@/core";
import type { Moment, PublishedDay } from "@msuite/katana";
import { useAuthContext } from "@msuite/picasso";
import { useAppContext } from "context/app";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export const usePublishListener = (dailyMoment: Moment) => {
	/** Context */
	const { validation } = useAuthContext();

	/** State */
	const [isPublished, setIsPublished] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [publishedDay, setPublishedDay] = useState<PublishedDay | undefined>(
		undefined,
	);
	const { activeWorkspace } = useAppContext();

	const dailyReference = `published/${activeWorkspace}/tage/${dailyMoment.daystamp()}`;

	/** Constants */
	useEffect(() => {
		const ref = doc(db, dailyReference);
		const unsubscribe = onSnapshot(ref, (snapshot) => {
			const data = snapshot.data() as PublishedDay;
			setIsPublished(snapshot.exists());
			setIsEditing(data?.isEditing ?? false);
			setPublishedDay(data);
		});
		return () => unsubscribe();
	}, [dailyReference]);

	const isBlocked = (isPublished && !isEditing) || !validation?.isAdmin;

	return { isPublished, isEditing, publishedDay, isBlocked };
};
