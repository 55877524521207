import type {
	Ticket,
	TicketEstimation,
	TicketEstimationUnit,
	TicketTypes,
	TrimbleConnectItem,
} from "@msuite/katana";
import { generateId } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import * as yup from "yup";

export const FormError = FormErrorMessage<Ticket>;

const ticketTypes: TicketTypes[] = [
	"Auf",
	"Ab",
	"Abholung",
	"Anlieferung",
	"Um",
	"Hinweis",
];

const estimationShape: Record<keyof TicketEstimation, yup.SchemaOf<any>> = {
	estimatedTime: yup
		.number()
		.default(0)
		.transform((value: number | undefined | "NaN") => {
			if (Number.isNaN(value)) return 0;
			if (value === undefined) return 0;
			return value;
		}),
	estimationUnit: yup
		.string()
		.default("hours")
		.transform((value: TicketEstimationUnit | undefined) => {
			const acceptable: TicketEstimationUnit[] = ["days", "hours"];
			if (value === undefined) return "hours";
			if (acceptable.includes(value)) return value;
			return "hours";
		}),
};

const trimbleConnectShape: Record<
	keyof TrimbleConnectItem,
	yup.SchemaOf<any>
> = {
	name: yup.string().optional(),
	trimbleLink: yup
		.string()
		.test("valid-trimble-link", "Ungültiger Link", (value) => {
			if (!value) return true;
			const hasSToken = value.includes("stoken=");
			if (value.includes("web.connect.trimble.com") && hasSToken) return true;
			if (value.includes("app.sketchup.com") && hasSToken) return true;
			return false;
		}),
};

const shape = {
	id: yup.string().default(generateId()).required(),
	ticket: yup
		.string()
		.required("Bitte geben Sie eine Ticket-Beschreibung ein."),
	type: yup.string().oneOf(ticketTypes).required(),
	date: yup.string().notRequired(),
	files: yup.array().of(yup.string()).default([]).notRequired(),
	date_is_deadline: yup.boolean().default(false).notRequired(),
	date_earliest_from: yup.string().notRequired(),
	estimation: yup.object().shape(estimationShape).notRequired(),
	trimbleConnect: yup
		.array()
		.of(yup.object().shape(trimbleConnectShape))
		.notRequired(),
	note: yup.string().notRequired(),
};

const shapeWithDefaults = {
	...shape,
	type: yup.string().oneOf(ticketTypes).default("Auf").required(),
	ticket: yup
		.string()
		.default("Ohne Beschreibung")
		.required("Bitte geben Sie eine Beschreibung ein."),
};

function ticketTransformer(values: Partial<Ticket>) {
	values.trimbleConnect =
		values.trimbleConnect?.filter !== undefined
			? values.trimbleConnect?.filter((item) => !!item.trimbleLink)
			: [];
	if (typeof values.date_is_deadline !== "boolean")
		values.date_is_deadline = false;
	if (typeof values.date !== "string") delete values.date;
	if (typeof values.date_earliest_from !== "string")
		delete values.date_earliest_from;
	if (typeof values.estimation === "undefined") delete values.estimation;
	if (typeof values.note !== "string") delete values.note;
	if (typeof values.trimbleConnect === "undefined")
		delete values.trimbleConnect;
	if (!values.trimbleConnect?.length) delete values.trimbleConnect;
	return values;
}

export const TicketSchema = yup
	.object()
	.transform(ticketTransformer)
	.shape(shape);

export const TicketSchemaWithDefaults = yup
	.object()
	.transform(ticketTransformer)
	.shape(shapeWithDefaults);
