import { moment } from "@msuite/katana";
import { FormControl, FormLabel, Grid, Text } from "@msuite/picasso";
import type { FC } from "react";
import { CallPriorityLabels } from "../form/schema";
import { useCallNoteOverviewContext } from "./context";

export const OverviewCallbackDate: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Render */
	return (
		<Grid
			templateColumns="1fr 1fr"
			rowGap={6}
			columnGap={6}
		>
			<FormControl>
				<FormLabel>Priorität</FormLabel>
				<Text>{CallPriorityLabels[callNote.priority]}</Text>
			</FormControl>
			<FormControl>
				<FormLabel>Rückrufzeitpunkt</FormLabel>
				<Text>{moment(callNote.callbackDate).longWithTime()}</Text>
			</FormControl>
		</Grid>
	);
};
