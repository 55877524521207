import type { ConstructionSiteContact } from "@msuite/katana";
import {
	type AutoCompleteRenderItemProps,
	Text,
	VStack,
} from "@msuite/picasso";

export const ClientAutoCompleteItem: AutoCompleteRenderItemProps<
	ConstructionSiteContact
> = ({ option }) => {
	return (
		<VStack
			spacing={0}
			alignItems="flex-start"
			width="100%"
		>
			<Text fontSize="sm">{option.item?.name}</Text>
		</VStack>
	);
};
