import { Image as ChakraImage } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { FileLightBox } from "./file-light-box";

/** Props Interface */
interface FileImageProps {
	src: string | undefined;
	imageSize: { width: number; height: number } | null;
	hasSelectFn?: boolean;
}

export const FileImage: FC<FileImageProps> = ({ src, hasSelectFn }) => {
	/** Constants */
	const ViewComponent = hasSelectFn ? Fragment : FileLightBox;

	/** Render */
	return (
		<ViewComponent>
			<ChakraImage
				src={src}
				width="100%"
				height="100%"
				loading="eager"
				objectFit="cover"
				cursor="pointer"
			/>
		</ViewComponent>
	);
};
