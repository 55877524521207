import { Center, Text } from "@msuite/picasso";
import type { FC } from "react";

export const EmptyNote: FC = () => {
	/** Render */
	return (
		<Center padding={12}>
			<Text fontSize="sm">Keine Baustellen vorhanden</Text>
		</Center>
	);
};
