import type { CallNote } from "@msuite/katana";
import { moment } from "@msuite/katana";
import { Box, HStack, Text, VStack, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbCircleCheckFilled, TbCircleHalf2 } from "react-icons/tb";
import { useProcessCallNotesModalContext } from "./context";

/** Props Interface */
interface CallNoteListItemProps {
	callNote: CallNote;
}

export const CallNoteListItem: FC<CallNoteListItemProps> = ({ callNote }) => {
	/** Context */
	const { colors } = useUIContext();
	const { selectedCallNote, setSelectedCallNote } =
		useProcessCallNotesModalContext();

	/** Computed */
	const isSelected = selectedCallNote?.id === callNote.id;
	const isAnswered = !!callNote.answeredAt;

	/** Functions */
	function handleSelectedCallNote(callNote: CallNote) {
		if (isSelected) {
			setSelectedCallNote(undefined);
		} else {
			setSelectedCallNote(callNote);
		}
	}

	return (
		<VStack
			key={callNote.id}
			paddingY={3}
			paddingLeft={6}
			paddingRight={3}
			spacing={0}
			_notLast={{ borderBottomWidth: 1 }}
			cursor="pointer"
			_hover={{ opacity: 0.5 }}
			_active={{ opacity: 0.3 }}
			onClick={() => handleSelectedCallNote(callNote)}
			backgroundColor={isSelected ? colors.grayLight : undefined}
		>
			<HStack justifyContent="space-between">
				<Text fontSize="sm">
					{moment(callNote.receivedAt).format("dd, L / HH:mm")}
				</Text>
				<Box color={isAnswered ? colors.emerald : colors.orange}>
					{isAnswered ? <TbCircleCheckFilled /> : <TbCircleHalf2 />}
				</Box>
			</HStack>
			<Text fontWeight="semibold">
				{callNote.caller.firstName} {callNote.caller.lastName}
			</Text>
			{callNote.caller.companyName && (
				<Text fontSize="sm">{callNote.caller.companyName}</Text>
			)}
		</VStack>
	);
};
