import {
	Button,
	ModalButtonGroup,
	ModalFooter,
	useModalContext,
} from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface FooterProps {
	isLoading: boolean;
	onPerform: () => void;
}

export const Footer: FC<FooterProps> = ({ isLoading, onPerform }) => {
	/** Context */
	const { onClose } = useModalContext();

	/** Render */
	return (
		<ModalFooter>
			<ModalButtonGroup>
				<Button
					onClick={onClose}
					variant="ghost"
				>
					abbrechen
				</Button>
				<Button
					onClick={onPerform}
					isLoading={isLoading}
					colorScheme="brand"
					aria-label="okay"
				>
					Fertig
				</Button>
			</ModalButtonGroup>
		</ModalFooter>
	);
};
