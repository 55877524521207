import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { useTicketCardContext } from "@/context/ticket-card";
import { TrimbleConnectModal } from "@/modals/misc/trimble-connect-modal";
import {
	Box,
	HStack,
	Text,
	Tooltip,
	useDisclosure,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { TbCube } from "react-icons/tb";

export const TicketTrimble: FC = () => {
	/** Context */
	const { ticket } = useTicketCardContext();
	const { isInView } = useConstructionSiteCardContext();
	const { colors } = useUIContext();

	/** Hooks */
	const trimbleConnectModal = useDisclosure();

	/** Constants */
	const trimbleLink = ticket?.trimbleConnect?.at(0)?.trimbleLink;

	if (!trimbleLink) return null;

	/** Render */
	return (
		<Fragment>
			<Tooltip label="3D-Modell">
				<HStack
					spacing={1}
					alignItems="center"
					fontSize="sm"
					_active={{
						opacity: 0.5,
					}}
					cursor="pointer"
					onClick={trimbleConnectModal.onOpen}
				>
					<Box color={colors.red}>
						<TbCube />
					</Box>
					<Text>1</Text>
				</HStack>
			</Tooltip>
			{isInView && trimbleConnectModal.isOpen && (
				<TrimbleConnectModal
					isOpen={trimbleConnectModal.isOpen}
					onClose={trimbleConnectModal.onClose}
					trimbleLink={trimbleLink}
				/>
			)}
		</Fragment>
	);
};
