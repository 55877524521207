import { Box, Grid, HStack, Text, VStack, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

export const CalendarBox: FC = () => {
	/** Hooks */
	/*
	const [currentDate, setCurrentDate] = useQueryState("date", {
		defaultValue: moment().format("YYYY-MM-DD"),
		clearOnDefault: false,
	});
	*/

	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Box
			height="0"
			flexGrow={1}
		>
			<VStack
				height="100%"
				overflowY="scroll"
				flexGrow={0}
				minH="0"
				spacing={0}
			>
				<Grid templateColumns="1fr 5fr">
					<div />
					<Box
						borderLeftWidth={1}
						height={6}
						width="100%"
						style={{
							transform: "translateX(-1px)",
						}}
						flexShrink={0}
						backgroundColor={colors.grayLight}
					/>
				</Grid>
				{Array.from({ length: 24 }).map((_, i) => (
					<Grid
						templateColumns="1fr 5fr"
						height="4rem"
						flexShrink={0}
						// biome-ignore lint: no-index-key
						key={i}
					>
						<HStack
							justifyContent="flex-end"
							alignItems="flex-start"
							borderRightWidth={1}
							height="100%"
							px="0.75rem"
						>
							<Text
								fontSize="sm"
								style={{
									transform: "translateY(-50%)",
								}}
							>{`${i.toString().padStart(2, "0")}:00`}</Text>
						</HStack>
						<Box
							height="100%"
							width="100%"
							borderTopWidth={1}
						/>
					</Grid>
				))}
			</VStack>
		</Box>
	);
};
