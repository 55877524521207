import type { ConstructionSite } from "@msuite/katana";
import { useInView } from "framer-motion";
import { type FC, useId, useRef } from "react";
import {
	ConstructionSiteCardContent,
	type ConstructionSiteCardProps,
} from "./construction-site-card-content";

export function findTicket(
	constructionSite: ConstructionSite,
	ticketId: string,
) {
	const ticket = constructionSite.tickets?.find(
		(ticket) => ticket.id === ticketId,
	);
	if (ticket) return ticket;
	return constructionSite.inactive?.find((ticket) => ticket.id === ticketId);
}

/** Props Interface */
export const ConstructionSiteCardFreeze: FC<
	ConstructionSiteCardProps & { shouldFreezeOutsideViewport?: boolean }
> = ({ shouldFreezeOutsideViewport = true, ...props }) => {
	/** Refs */
	const ref = useRef() as React.RefObject<HTMLDivElement>;
	const isInView = useInView(ref);

	const renderId = useId();

	/** Render */
	return (
		<div
			ref={ref}
			id={renderId}
		>
			<ConstructionSiteCardContent
				{...props}
				isInView={isInView}
			/>
		</div>
	);
};
