import type { Moment } from "@msuite/katana";
import type { FC } from "react";
import { BaseItem } from "./base-item";
import { useDatePickerContext } from "./context";

/** Props Interface */
interface DayItemProps {
	tabIndex: number;
	date: Moment;
	week: Moment;
}

export const DayItem: FC<DayItemProps> = ({ date, tabIndex }) => {
	/** Context */
	const { currentMonth, holidays } = useDatePickerContext();

	/** Constants */
	const dateString = date.format("DD");
	const isOutOfScope = !date.isSame(currentMonth, "month");
	const isWeekend = date.isWeekend();
	const isHoliday = holidays.includes(date.format("YYYY-MM-DD"));

	/** Render */
	return (
		<BaseItem
			moment={date}
			mode="day"
			isInactive={isOutOfScope}
			hasAccent={isWeekend}
			hasHint={isHoliday}
			tabIndex={tabIndex}
		>
			{dateString}
		</BaseItem>
	);
};
