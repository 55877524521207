import { FormErrorMessage } from "@msuite/picasso";
import { z } from "zod";

export const campaignInvitationModalFormSchema = z.object({
	title: z.union([z.literal("mr"), z.literal("mrs")]),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string().email(),
});

export type CampaignInvitationModalFormType = z.infer<
	typeof campaignInvitationModalFormSchema
>;

export const FormError = FormErrorMessage<CampaignInvitationModalFormType>;
