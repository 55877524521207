import { HStack, Text } from "@msuite/picasso";
import type { FC } from "react";

export const MultipleChoicesPossible: FC = () => {
	return (
		<HStack
			spacing={1}
			fontSize="sm"
			fontWeight="semibold"
		>
			<Text color="brand.500">*</Text>
			<Text>Mehrfach Antworten möglich</Text>
		</HStack>
	);
};
