import { FileCard } from "@/components/card";
import { Center, Text, VStack, useUIContext } from "@msuite/picasso";
import { type FC, useEffect, useState } from "react";
import { type Accept, useDropzone } from "react-dropzone";
import { IoFileTray } from "react-icons/io5";

/** Props Interface */
interface DropzoneProps {
	onFilesChange: (files: File[]) => void;
	hidePreview?: boolean;
	defaultFiles?: File[];
	onRemoveFile: (fileIndex: number) => void;
	maxFiles?: number;
	accept?: Accept;
	label?: string;
}

export const Dropzone: FC<DropzoneProps> = ({
	onFilesChange,
	defaultFiles,
	onRemoveFile,
	maxFiles,
	accept,
	hidePreview,
	label,
}) => {
	/** State */
	const [files, setFiles] = useState<File[]>([]);

	/** Context */
	const { colors } = useUIContext();

	/** Functions */
	function handleOnDrop(newFiles: File[]) {
		setFiles([...newFiles, ...(defaultFiles ?? [])]);
		onFilesChange([...newFiles, ...(defaultFiles ?? [])]);
	}

	function handleOnRemove(fileIndex: number) {
		setFiles((prev) => prev.filter((_, i) => i !== fileIndex));
		onRemoveFile?.(fileIndex);
	}

	/** Dropzone */
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleOnDrop,
		maxFiles,
		accept,
		multiple: maxFiles !== 1,
	});

	/** Effects */
	useEffect(() => {
		setFiles(defaultFiles ?? []);
	}, [defaultFiles]);

	/** Computed */
	const maxFilesReached = maxFiles && files.length >= maxFiles;
	const dropzoneLabel = label || "Dateien hier ablegen";

	/** Render */
	return (
		<VStack>
			{!maxFilesReached && (
				<Center
					{...getRootProps()}
					width="100%"
					py={8}
					backgroundColor={colors.whiteAdjusted}
					borderWidth={1}
					borderStyle="dashed"
					rounded="lg"
					cursor="pointer"
					_hover={{
						borderColor: colors.brand,
						borderStyle: "solid",
					}}
					_active={{
						opacity: 0.8,
					}}
				>
					<VStack
						alignItems="center"
						spacing={1}
						width="100%"
					>
						<IoFileTray />
						<Text fontSize="xs">{dropzoneLabel}</Text>
					</VStack>
					<input
						{...getInputProps()}
						style={{ display: "none" }}
					/>
				</Center>
			)}
			{!hidePreview &&
				files.map((file, index) => (
					<FileCard
						key={file.name}
						file={file}
						onRemove={() => handleOnRemove(index)}
					/>
				))}
		</VStack>
	);
};
