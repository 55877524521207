import { VStack } from "@msuite/picasso";
import type { FC } from "react";
import { AddNote } from "./add-note";

export const Notes: FC = () => {
	/** Render */
	return (
		<VStack>
			<AddNote />
		</VStack>
	);
};
