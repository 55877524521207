import { FileCardUploaded } from "@/components/card/file-card";
import { useFileGalleryModalContext } from "@/context/file-gallery-modal";
import { Grid, Label, VStack } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface GalleryProps {
	type: "existing" | "related";
	label: string;
}

export const Gallery: FC<GalleryProps> = ({ type, label }) => {
	/** Context */
	const { existingFileNames, existingRelatedFileNames, path } =
		useFileGalleryModalContext();
	const fileNames =
		type === "existing" ? existingFileNames : existingRelatedFileNames;

	/** Render */
	return (
		<VStack spacing={2}>
			<Label>{label}</Label>
			<Grid
				gap={4}
				templateColumns="repeat(auto-fill, minmax(8rem, 1fr))"
			>
				{fileNames.map((fileName, i) => (
					<FileCardUploaded
						fileName={fileName}
						path={path}
						key={fileName + i * 2}
						withoutDelete={type === "related"}
					/>
				))}
			</Grid>
		</VStack>
	);
};
