import { useHandleRentListModalContext } from "@/context/handle-rent-list-modal";
import {
	Heading,
	Input,
	ModalHeader,
	SegmentedControl,
	VStack,
} from "@msuite/picasso";
import { type FC, useMemo } from "react";
import { IoSearch } from "react-icons/io5";

export const HandleRentListModalHeader: FC = () => {
	/** Context */
	const {
		currentState,
		setCurrentState,
		rentList,
		globalFilter,
		setGlobalFilter,
	} = useHandleRentListModalContext();

	const countByState = useMemo(() => {
		return {
			all: rentList?.length ?? 0,
			yes: rentList?.filter((item) => item.answer === "yes").length ?? 0,
			no: rentList?.filter((item) => item.answer === "no").length ?? 0,
			maybe: rentList?.filter((item) => item.answer === "maybe").length ?? 0,
			unanswered:
				rentList?.filter((item) => item.answer === "unanswered").length ?? 0,
		};
	}, [rentList]);

	/** Render */
	return (
		<ModalHeader>
			<VStack>
				<Heading size="md">Mietabfrage bearbeiten</Heading>
				<SegmentedControl
					options={[
						{ label: "Alle", value: "all", icon: countByState.all || "0" },
						{ label: "Ja", value: "yes", icon: countByState.yes || "0" },
						{ label: "Nein", value: "no", icon: countByState.no || "0" },
						{
							label: "Vielleicht",
							value: "maybe",
							icon: countByState.maybe || "0",
						},
						"spacer",
						{
							label: "Nicht beantwortet",
							value: "unanswered",
							icon: countByState.unanswered || "0",
						},
					]}
					value={currentState}
					onChange={setCurrentState}
				/>
				<Input
					value={globalFilter}
					onChange={(e) => setGlobalFilter(e.target.value)}
					leftIcon={<IoSearch />}
					placeholder="Suchen..."
					fontWeight="normal"
				/>
			</VStack>
		</ModalHeader>
	);
};
