import { type Ticket, getTicketEstimationString } from "@msuite/katana";
import { GridItem, Text, useColorModeValue } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface TicketEstimationProps {
	ticket: Ticket;
}

export const TicketEstimation: FC<TicketEstimationProps> = ({ ticket }) => {
	/** Hooks */
	const fontColor = useColorModeValue("gray.700", "gray.300");

	/** Fallback */
	if (ticket.estimation === undefined) return null;
	if (!ticket.estimation.estimatedTime) return null;

	/** Values */
	const estimationString = getTicketEstimationString(ticket.estimation);

	/** Render */
	return (
		<GridItem
			rowStart={2}
			alignSelf="center"
		>
			<Text
				color={fontColor}
				fontSize="sm"
			>
				&#8764; {estimationString}
			</Text>
		</GridItem>
	);
};
