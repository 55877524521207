import { ax, db } from "@/core";
import { getWeeklyReferenceString } from "@/sites/plan/use-plan";
import type {
	ConstructionSite,
	Entry,
	SeparateCommentEntry,
	Ticket,
} from "@msuite/katana";
import { createEntrySections, moment } from "@msuite/katana";
import { arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";

export async function addConstructionSiteToWeekly(
	constructionSite: ConstructionSite,
	activeWorkspace: string | null | undefined,
) {
	if (!activeWorkspace) return;
	if (!constructionSite.id) return;
	const tickets: Array<Ticket> = constructionSite.tickets ?? [];
	const promises = [];
	for (const ticket of tickets) {
		if (!ticket.date) continue;
		const currentMoment = moment().convertTicketDateToMoment(ticket.date);
		const weeklyReference = getWeeklyReferenceString(
			activeWorkspace,
			currentMoment,
		);
		const weeklyRef = doc(db, weeklyReference);
		promises.push(
			updateDoc(weeklyRef, {
				baustellen: arrayUnion(constructionSite.id),
			}).catch(async () => {
				await setDoc(weeklyRef, { baustellen: [constructionSite.id] });
			}),
		);
	}
	await Promise.all(promises);
}

export async function fetchConstructionSiteEntries(
	constructionSiteId: string,
	from: string,
	to: string,
) {
	try {
		const { data } = await ax.get(
			`/v2/construction-site/${constructionSiteId}/diary-entries?from=${from}&to=${to}`,
		);
		const docs = data.docs as Entry[];
		const entries = docs.filter((entry) => {
			if (["folder", "publish"].includes(entry.entryType)) return true;
			if (
				entry.entryType === "separate-comment" &&
				(entry as unknown as SeparateCommentEntry).specialComment
			)
				return true;
		});
		const sections = createEntrySections(entries, [], true);
		return sections;
	} catch (error) {
		console.error(error);
	}
}

export function createConstructionSiteAutoCompleteOptions(
	constructionSites: ConstructionSite[] | undefined,
	excludeId?: string,
) {
	return (
		(constructionSites ?? []).map((constructionSite) => ({
			label: constructionSite.bauvorhaben ?? "",
			value: constructionSite.id ?? "",
			item: constructionSite,
		})) ?? []
	).filter((option) => option.value !== excludeId);
}

export function openGoogleMaps(constructionSite: ConstructionSite | undefined) {
	try {
		const baseURL = "https://www.google.com/maps/search/?api=1&query=";
		const { strasse, hausnummer, plz, ort } = constructionSite ?? {};
		window.open(`${baseURL}${strasse}+${hausnummer}+${plz}+${ort}`, "_blank");
	} catch (error) {
		console.error(error);
	}
}
