import { Center, Spinner, Text, VStack } from "@msuite/picasso";
import type { FC } from "react";

export const LoadingScreen: FC = () => {
	/** Render */
	return (
		<Center
			position="absolute"
			left="0"
			right="0"
			top="0"
			bottom="0"
			px="10vw"
		>
			<VStack
				spacing={6}
				alignItems="center"
			>
				<Spinner />
				<Text fontSize="sm">Umfrage wird geladen...</Text>
			</VStack>
		</Center>
	);
};
