import { HStack, IconButton, Text } from "@msuite/picasso";
import type { FC } from "react";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import { useDatePickerContext } from "./context";

export const HeaderControls: FC = () => {
	/** Context */
	const { currentMonth, setCurrentMonth } = useDatePickerContext();

	/** Functions */
	function handleChangeMonth(direction: "subtract" | "add") {
		const newMonth = currentMonth.clone()[direction](1, "month");
		setCurrentMonth(newMonth);
	}

	/** Render */
	return (
		<HStack justify="space-between">
			<IconButton
				size="sm"
				aria-label="Previous Month"
				variant="ghost"
				icon={<TbChevronLeft />}
				onClick={() => handleChangeMonth("subtract")}
			/>
			<Text fontWeight="medium">{currentMonth.format("MMMM YYYY")}</Text>
			<IconButton
				size="sm"
				aria-label="Next Month"
				variant="ghost"
				icon={<TbChevronRight />}
				onClick={() => handleChangeMonth("add")}
			/>
		</HStack>
	);
};
