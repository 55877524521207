import { ax } from "@/core";
import { DASHBOARD_CALL_NOTES_LAYOUT_ID } from "@/sites/home/use-dashboard";
import type { CallNote, DashboardCallNotes } from "@msuite/katana";
import {
	Modal,
	ModalCloseButton,
	ModalContentContainer,
	ModalOverlay,
	useApi,
} from "@msuite/picasso";
import { type FC, useEffect, useState } from "react";
import { Content } from "./content";
import { ProcessCallNotesModalContext } from "./context";

/** Props Interface */
interface ProcessCallNotesModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const ProcessCallNotesModal: FC<ProcessCallNotesModalProps> = ({
	isOpen,
	onClose,
}) => {
	/** State */
	const [selectedCallNote, setSelectedCallNote] = useState<
		CallNote | undefined
	>(undefined);
	const [queryLimit, setQueryLimit] = useState<number>(20);

	/** Hooks */
	const { data, refetch } = useApi<DashboardCallNotes>(ax, {
		url: `/v2/dashboard/call-notes?queryLimit=${queryLimit}`,
		dependencies: ["dashboard", "call-notes"],
		refetchOnMount: true,
	});

	/** Functions */
	function handleOnClose() {
		onClose();
		setSelectedCallNote(undefined);
		setQueryLimit(10);
	}

	/** Effects */
	useEffect(() => {
		refetch();
	}, [isOpen]);

	/** Render */
	return (
		<ProcessCallNotesModalContext.Provider
			value={{
				callNotes: data?.callNotes ?? [],
				unansweredCallNotes: data?.unansweredCallNotes ?? 0,
				selectedCallNote,
				setSelectedCallNote,
				queryLimit,
				setQueryLimit,
				refetch,
			}}
		>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<ModalContentContainer
					width="100rem"
					layoutId={DASHBOARD_CALL_NOTES_LAYOUT_ID}
				>
					<ModalCloseButton />
					<Content />
				</ModalContentContainer>
			</Modal>
		</ProcessCallNotesModalContext.Provider>
	);
};
