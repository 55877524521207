import { Box } from "@msuite/picasso";
import type { FC } from "react";

export const HintElement: FC = () => {
	/** Render */
	return (
		<Box
			sx={{
				position: "absolute",
				top: "0.25rem",
				right: "0.25rem",
				width: "5px",
				aspectRatio: "1",
				borderRadius: "50%",
				backgroundColor: "brand.500",
			}}
		/>
	);
};
