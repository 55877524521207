import { moment } from "@msuite/katana";
import { HStack, IconButton, Text, Tooltip } from "@msuite/picasso";
import { useQueryState } from "nuqs";
import type { FC } from "react";
import { IoCalendar, IoChevronBack, IoChevronForward } from "react-icons/io5";

export const Controls: FC = () => {
	/** Hooks */
	const [currentDate, setCurrentDate] = useQueryState("date", {
		defaultValue: moment().format("YYYY-MM-DD"),
		clearOnDefault: false,
	});

	/** Functions */
	function handleOnReset() {
		setCurrentDate(moment().format("YYYY-MM-DD"));
	}

	function handleOnBack() {
		setCurrentDate(moment(currentDate).subtract(1, "day").format("YYYY-MM-DD"));
	}

	function handleOnForward() {
		setCurrentDate(moment(currentDate).add(1, "day").format("YYYY-MM-DD"));
	}

	/** Render */
	return (
		<HStack
			px={4}
			py={3}
			borderBottomWidth={1}
			justifyContent="space-between"
		>
			<Text fontWeight="600">{moment(currentDate).format("dddd, L")}</Text>
			<HStack spacing={2}>
				<Tooltip label="Heute">
					<IconButton
						aria-label="reset"
						icon={<IoCalendar />}
						size="xs"
						onClick={handleOnReset}
					/>
				</Tooltip>
				<IconButton
					aria-label="back"
					icon={<IoChevronBack />}
					size="xs"
					onClick={handleOnBack}
				/>
				<IconButton
					aria-label="forward"
					icon={<IoChevronForward />}
					size="xs"
					onClick={handleOnForward}
				/>
			</HStack>
		</HStack>
	);
};
