import { FormControl, FormLabel, Input, Select, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { type CampaignInvitationModalFormType, FormError } from "./schema";

export const CampaignInvitationModalForm: FC = () => {
	/** Hooks */
	const methods = useFormContext<CampaignInvitationModalFormType>();

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Anrede</FormLabel>
				<Select
					placeholder="Bitte auswählen"
					{...methods.register("title")}
				>
					<option value="mr">Herr</option>
					<option value="mrs">Frau</option>
				</Select>
				<FormError name="title" />
			</FormControl>
			<FormControl>
				<FormLabel>Vorname</FormLabel>
				<Input
					placeholder="z.B. Max"
					{...methods.register("firstName")}
				/>
				<FormError name="firstName" />
			</FormControl>
			<FormControl>
				<FormLabel>Nachname</FormLabel>
				<Input
					placeholder="z.B. Mustermann"
					{...methods.register("lastName")}
				/>
				<FormError name="lastName" />
			</FormControl>
			<FormControl>
				<FormLabel>E-Mail</FormLabel>
				<Input
					placeholder="z.B. max@mustermann.de"
					{...methods.register("email")}
				/>
				<FormError name="email" />
			</FormControl>
		</VStack>
	);
};
