import type { CallNote } from "@msuite/katana";
import { createContext, useContext } from "react";

/** Interface */
interface ICallNoteOverviewContext {
	callNote: CallNote;
}

export const CallNoteOverviewContext = createContext<ICallNoteOverviewContext>({
	callNote: {} as CallNote,
});

/** Hooks */
export const useCallNoteOverviewContext = () => {
	return useContext(CallNoteOverviewContext);
};
