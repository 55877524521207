import { HandleRentListModalContext } from "@/context/handle-rent-list-modal";
import { ax, db } from "@/core";
import type { RentListAnswer, RentListItem } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	toast,
	useAuthContext,
	useCollection,
	useQueryClient,
} from "@msuite/picasso";
import { type FC, useState } from "react";
import { HandleRentListModalBody } from "./body";
import { HandleRentListModalHeader } from "./header";

/** Props Interface */
interface HandleRentListModalProps {
	isOpen: boolean;
	onClose: () => void;
	onComplete?: () => void;
}

export const HandleRentListModal: FC<HandleRentListModalProps> = ({
	isOpen,
	onClose,
	onComplete,
}) => {
	/** Context */
	const { userId } = useAuthContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [globalFilter, setGlobalFilter] = useState<string>("");
	const [currentState, setCurrentState] = useState<RentListAnswer | "all">(
		"all",
	);

	/** Hooks */
	const qc = useQueryClient();
	const { data: rentList } = useCollection<RentListItem>(db, {
		path: `user/${userId || "q"}/rentlist`,
		subscribe: true,
	});

	const isDone = rentList?.every((item) => item.answer !== "unanswered");

	/** Functions */
	async function handleFinishRentList() {
		try {
			setIsLoading(true);
			toast.promise(ax.post(`/v2/user/${userId}/rent-list/finish`), {
				loading: "Mietabfrage wird abgeschlossen...",
				success: () => {
					setIsLoading(false);
					onComplete?.();
					onClose();
					return "Mietabfrage wurde erfolgreich abgeschlossen.";
				},
				error: () => {
					setIsLoading(false);
					qc.refetchQueries({
						queryKey: ["rent-list", userId ?? ""],
						exact: true,
					});
					return "Mietabfrage konnte nicht abgeschlossen werden.";
				},
			});
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<HandleRentListModalContext.Provider
			value={{
				currentState,
				setCurrentState,
				rentList,
				globalFilter,
				setGlobalFilter,
			}}
		>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent width="70rem">
					<ModalCloseButton />
					<HandleRentListModalHeader />
					<HandleRentListModalBody />
					<ModalFooter>
						<ModalButtonGroup>
							<Button
								onClick={handleFinishRentList}
								isDisabled={!isDone}
								isLoading={isLoading}
							>
								Mietabfrage abschließen
							</Button>
						</ModalButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</HandleRentListModalContext.Provider>
	);
};
