import { CompleteDeputiesModal } from "@/modals/my-space/complete-deputies-modal";
import type { IGetUnfinishedDeputiesApiResponse } from "@msuite/katana";
import { useApi, useAuthContext, useDisclosure } from "@msuite/picasso";
import { ax } from "core";
import { useEffect, type FC } from "react";

export const DeputiesControl: FC = () => {
	/** Context */
	const { user } = useAuthContext();

	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { data } = useApi<IGetUnfinishedDeputiesApiResponse>(ax, {
		url: `/v2/employees/${user?.id}/deputies/unfinished`,
		dependencies: ["unfinished-deputies", user?.id ?? ""],
	});

	/** Effects */
	useEffect(() => {
		if (!data?.deputies) return;
		if (data.deputies.length > 0) {
			onOpen();
		}
	}, [data, onOpen]);

	/** Render */
	return (
		<CompleteDeputiesModal
			isOpen={isOpen}
			onClose={onClose}
			deputies={data?.deputies ?? []}
		/>
	);
};
